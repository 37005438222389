import { Component, OnInit } from '@angular/core';
import { CursoService } from '../curso.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss']
})
export class UploadComponent implements OnInit {

  fileUpload : any
  nome: String
  description: String
  capitulo: String
  fileInput: any
  fileName: String
  

  constructor(private cursoService: CursoService, 
              private activatedRoute: ActivatedRoute ) {

    this.capitulo = this.activatedRoute.snapshot.params['capituloId']==undefined ? undefined : this.activatedRoute.snapshot.params['capituloId']

  }

  ngOnInit() {
  }

  changeUpload(event){
    this.fileUpload = event.target
    this.fileName = this.fileUpload.files[0].name
  }

  // upload(){
  //   this.cursoService.uploadVimeo(this.fileUpload, this.nome, this.description, 'V', this.capitulo).subscribe(result=>{

  //     this.fileUpload = ''
  //     this.fileName = ''
  //   },(error)=>{
  //     console.log('error=>', error)
  //   })
  // }

}
