import { Component, OnInit } from '@angular/core'
import { UtilService } from '../shared/util/util.service';
import { MatPaginator } from '@angular/material';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html'
})

export class HomeComponent implements OnInit {
  constructor(public utilService: UtilService) {}

  ngOnInit() {

  }
}