import { Component, OnInit, ViewChild } from '@angular/core';
import swal from 'sweetalert2';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { CursoService } from '../curso.service';
import { Router } from '@angular/router';
import { Concurso } from '../curso.model';

@Component({
  selector: 'app-concurso',
  templateUrl: './concurso.component.html',
  styleUrls: ['./concurso.component.scss']
})
export class ConcursoComponent implements OnInit {

  displayedColumns = ['status', 'nome', 'categoria', 'acao']
  dataSource: MatTableDataSource<Concurso>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(public cursoService : CursoService,
              private router: Router) { }

  ngOnInit() {
    this.load()
  }

  ngAfterViewInit(){
    if(this.dataSource!=undefined){
      this.dataSource.sort = this.sort
    }
  }

  delete(id){

    swal({
      title: 'Potencial Global',
      text: 'Deseja realmente excluir esse concurso?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: "#DD6B55",
      confirmButtonText: "Sim",
      cancelButtonText: "Cancelar"
    })
    .then(retorno => {
      if (retorno.value) {    
        this.cursoService.deleteConcurso(id).subscribe(res=>{
          this.load()
        })
      }
    })

  }

  doFilter(filterValue:string){
    this.dataSource.filter = filterValue.trim().toLocaleLowerCase()
  }

  load(){

    swal({
      title: 'Carregando dados'
    });
    swal.showLoading();

    this.cursoService.obterConcursosGrid()
    .subscribe(retorno => {

      this.dataSource = new MatTableDataSource(retorno);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      swal.close()})
  }

  update(idConcurso: number){
    this.router.navigate(['dashboard/cursos/concursos/form',idConcurso]);
  }

}