import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import swal from 'sweetalert2';
import { FormBuilder, Validators, FormGroupDirective, FormGroup, FormArray } from '@angular/forms';
import { CursoService } from '../../curso.service';
import { QuestionsService } from '../questions.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatPaginator, MatSort, MatTableDataSource, MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { BACKEND_FILE } from 'src/app/app.api';
import { DialogData } from '../../concurso/formconcurso/formconcurso.component';

@Component({
  selector: 'app-formquestions',
  templateUrl: './formquestions.component.html',
  styleUrls: ['./formquestions.component.scss']
})
export class FormquestionsComponent implements OnInit {

  @ViewChild(FormGroupDirective) cadastroForm: FormGroup
  cursos: any
  module: any
  answerItem = {titulo: '', correta:'', id:'', questoesId: ''}
  itemsForm: FormArray
  questionId: number = undefined
  updateStatus: boolean = false

  id : number = undefined
  modulosCom: any[] = []
  foto: any

  displayedColumnsCom = ['nome', 'acao']
  displayedColumnsSem = ['nome', 'acao']

  //amterial
  dataSourceSem: MatTableDataSource<any>;
  dataSourceCom: MatTableDataSource<any>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private fb: FormBuilder, 
              private cursoService: CursoService,
              private questionService: QuestionsService,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              public dialog: MatDialog) { 

                this.questionId = this.activatedRoute.snapshot.params['id']==undefined ? undefined : this.activatedRoute.snapshot.params['id']

              }

  ngOnInit() {
    this.cadastroForm = this.fb.group({
      pergunta: this.fb.control('', [Validators.required]),
      status: this.fb.control(''),
      respostas: !this.updateStatus ? this.fb.array([]) : this.fb.array([this.createFieldAnswer()])
    })

    this.loadModulo(0)
    
  }

  ngAfterViewInit(){
    this.loadEdit(this.questionId)
  }

  loadCursos(){
    this.cursoService.obterCursos().subscribe(result=>{
      this.cursos = result
    })
  }
  
  loadEdit(id){
    if(id){
      this.questionService.obterQuestionId(id).subscribe(result => {
        
        this.cursoService.getCursoModulo(result.cursoId).subscribe(modulos=>{
          this.itemsForm = this.cadastroForm.controls['respostas'] as FormArray

          result.questoes_items.map(control => this.itemsForm.push(this.fb.group(control)));
          this.module = modulos
          this.updateStatus = !this.updateStatus

          this.cadastroForm.patchValue({
            pergunta: result.titulo,
            status: result.status
          })
        })
      })
      this.retornarFoto()
    }
  }

  loadModule(event){
    this.cursoService.getCursoModulo(event.value).subscribe(result=>{
      this.module = result
    })
  }

  createFieldAnswer(): FormGroup{
    return this.fb.group(this.answerItem)
  }

  addFieldAnswer(event: any): void{
    this.itemsForm = this.cadastroForm.controls['respostas'] as FormArray
    this.itemsForm.push(this.createFieldAnswer())
  }

  removeFieldAnswer(form: any): void{
    this.itemsForm = this.cadastroForm.controls['respostas'] as FormArray
    this.itemsForm.removeAt(form)
  }

  save(form: any){
      this.questionService.createQuestion(form).subscribe(result=>{
        this.questionService.createModuloPerguntas(result.id, this.modulosCom).subscribe(perguntaModulo=>{
          swal({
            title: 'Protencial Global',
            text: 'Perguntas/Respostas cadastrado com sucesso!',
            type: 'success',
            showConfirmButton: false,
            timer: 1500
          })
          setTimeout( () => {
            this.router.navigate(['/dashboard/cursos/questions'])
          }, 2000 );
        })
    }, (error)=>{
      swal({
        title: 'Protencial Global',
        text: 'Ocorreu um erro no cadastro de preguntas/respostas.',
        type: 'warning',
        showConfirmButton: false,
        timer: 2000
    })
    })
  }

  update(form: any){

    let question = form
    question.id = this.activatedRoute.snapshot.params['id']
    this.questionService.updateQuestion(question).subscribe(result=>{
      this.questionService.createModuloPerguntas(question.id, this.modulosCom).subscribe(perguntaModulo=>{
        swal({
          title: 'Protencial Global',
          text: 'Perguntas/Respostas cadastrado com sucesso!',
          type: 'success',
          showConfirmButton: false,
          timer: 1500
        })
        setTimeout( () => {
          this.router.navigate(['/dashboard/cursos/questions'])
        }, 2000 );
      })
      
    }, (error)=>{
      swal({
        title: 'Protencial Global',
        text: 'Ocorreu um erro no cadastro de preguntas/respostas.',
        type: 'warning',
        showConfirmButton: false,
        timer: 2000
    })
    })
  }

  doFilterSem(filterValue:string){
    this.dataSourceSem.filter = filterValue.trim().toLocaleLowerCase()
  }


  doFilterCom(filterValue:string){
    this.dataSourceCom.filter = filterValue.trim().toLocaleLowerCase()
  }

  loadModulo(com: any){
        
    this.questionService.obterModuloSemPerguntas(this.questionId, com).subscribe(result => {
      this.dataSourceSem = new MatTableDataSource(result);
      this.dataSourceSem.paginator = this.paginator;
      this.dataSourceSem.sort = this.sort;
      swal.close()
    })

    this.questionService.obterModuloPerguntas(this.questionId, com).subscribe(result => {
      this.dataSourceCom = new MatTableDataSource(result);
      this.dataSourceCom.paginator = this.paginator;
      this.dataSourceCom.sort = this.sort;
      if(this.modulosCom.length==0){
        result.forEach(element => {
          this.modulosCom.push(element.id)        
        }); 
      }      
    })
  }

  incluir(id: number){
        
    swal({
      title: 'Incluindo...'
    });
    swal.showLoading();

    this.modulosCom.push(id)
    this.loadModulo(this.modulosCom)
  }

  excluir(id: number){
        
    swal({
      title: 'Removendo...'
    });
    swal.showLoading();

    let index:number = this.modulosCom.findIndex(result=> result === id)

    this.modulosCom.splice(index,1)

    if(this.modulosCom.length==0){
      this.loadModulo('-1')  
    }else{
      this.loadModulo(this.modulosCom)  
    }
  }
  openDialog(): void {
    const dialogRef = this.dialog.open(UploadPerguntaFoto, {
      width: '400px',
      height: '600px',
      data:{ id : this.questionId}
    });
  
    dialogRef.afterClosed().subscribe(result => {
      this.foto = undefined
      setTimeout( () => {
        this.retornarFoto()
      }, 3000 );
    });
  }

  retornarFoto(){
    this.cursoService.obterPerguntaFoto().subscribe(result=>{
      if(result){
        result.forEach(element => {
          let valida: string = element
          if(this.questionId.toString() == valida.substring(0,this.questionId.toString().length)){
            this.foto = BACKEND_FILE + "/pergunta/" + element
          }
        });
      }
    })
  }


}



@Component({
  selector: 'uploadperguntafoto',
  templateUrl: './uploadperguntafoto.html',
})

export class UploadPerguntaFoto {
  imageChangedEvent: any = '';
  croppedImage: any = ''

  constructor(private cursoService: CursoService,
              public dialogRef: MatDialogRef<UploadPerguntaFoto>,
              @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }
  imageLoaded() {
      // show cropper
  }
  cropperReady() {
      // cropper ready
  }
  loadImageFailed() {
      // show message
  }

  upload(){

    this.cursoService.uploadPerguntaFoto(this.croppedImage, this.data.id).subscribe(result=>{

    },(error)=>{
      console.log('error=>', error)
    })
    this.onNoClick()
  }
}