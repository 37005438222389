import { HttpClient} from '@angular/common/http';
import { Observable } from 'rxjs';
import { DadosCEP } from './cep.model';
import { Injectable } from '@angular/core';
import { BACKEND_API } from '../../app.api'

@Injectable()

export class CepService{

  constructor(private http: HttpClient){}
    
  buscaCEP(cep: string): Observable<DadosCEP> {
    
    return this.http.get<DadosCEP>(`${BACKEND_API}/util/postal_code/${cep}`)

  }

  listaEstados(): any[] {
    let estados: any[]=[
        { "uf" : "AC",  "nome" : "Acre" },
        { "uf" : "AL",  "nome" : "Alagoas" },
        { "uf" : "AP",  "nome" : "Amapá" },
        { "uf" : "AM",  "nome" : "Amazonas" },
        { "uf" : "BA",  "nome" : "Bahia" },
        { "uf" : "CE",  "nome" : "Ceará" },
        { "uf" : "DF",  "nome" : "Distrito Federal" },
        { "uf" : "ES",  "nome" : "Espírito Santo" },
        { "uf" : "GO",  "nome" : "Goiás" },
        { "uf" : "MA",  "nome" : "Maranhão" },
        { "uf" : "MT",  "nome" : "Mato Grosso" },
        { "uf" : "MS",  "nome" : "Mato Grosso do Sul" },
        { "uf" : "MG",  "nome" : "Minas Gerais" },
        { "uf" : "PA",  "nome" : "Pará" },
        { "uf" : "PB",  "nome" : "Paraíba" },
        { "uf" : "PR",  "nome" : "Paraná" },
        { "uf" : "PE",  "nome" : "Pernambuco" },
        { "uf" : "PI",  "nome" : "Piauí" },
        { "uf" : "RJ",  "nome" : "Rio de Janeiro" },
        { "uf" : "RN",  "nome" : "Rio Grande do Norte" },
        { "uf" : "RS",  "nome" : "Rio Grande do Sul" },
        { "uf" : "RO",  "nome" : "Rondônia" },
        { "uf" : "RR",  "nome" : "Roraima" },
        { "uf" : "SC",  "nome" : "Santa Catarina" },
        { "uf" : "SP",  "nome" : "São Paulo" },
        { "uf" : "SE",  "nome" : "Sergipe" },
        { "uf" : "TO",  "nome" : "Tocantins" }
      ]
    return estados
  }
}