import { Component, OnInit, ViewChild } from '@angular/core';
import swal from 'sweetalert2';
import { QuestionsService } from './questions.service';
import { MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { Question } from './question.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-questions',
  templateUrl: './questions.component.html',
  styleUrls: ['./questions.component.scss']
})
export class QuestionsComponent implements OnInit {


  displayedColumns = ['titulo',  'acao']
  dataSource: MatTableDataSource<Question>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  
  constructor(private questionService: QuestionsService, 
              private router: Router) { }

  ngOnInit() {
    this.load()
  }

  load(){

    swal({
      title: 'Carregando dados'
    });
    swal.showLoading();

    this.questionService.obterQuestion()
    .subscribe(retorno => {
      this.dataSource = new MatTableDataSource(retorno);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      swal.close()})
  }

  doFilter(filterValue:string){
    this.dataSource.filter = filterValue.trim().toLocaleLowerCase()
  }

  update(id: number){
    this.router.navigate(['dashboard/cursos/questions/form',id]);
  }

}
