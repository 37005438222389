import { Component, OnInit, ViewChild, Inject, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormGroupDirective } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { CursoService } from '../../curso.service';
import { Categoria, Concurso } from '../../curso.model';
import swal from 'sweetalert2';
import { HttpErrorResponse } from '@angular/common/http';
import { CepService } from 'src/app/shared/cep/cep.service';
import { Estado } from 'src/app/shared/cep/cep.model';
import { UtilService } from 'src/app/shared/util/util.service';
import { MatSort, MatTableDataSource, MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material';
import { DialogData } from '../../capitulo/formcapitulo/formcapitulo.component';

export interface DialogData {
  id: number;
}

@Component({
  selector: 'app-formconcurso',
  templateUrl: './formconcurso.component.html',
  styleUrls: ['./formconcurso.component.scss']
})

export class FormconcursoComponent implements OnInit {

  @ViewChild('nome') inputNome:ElementRef;
  @ViewChild(FormGroupDirective) cadastroForm: FormGroup
  
  concurso: Concurso
  categoria : Categoria[]
  estados: Estado[]
  cidades: any[]

  displayedColumnsCursoCom = ['nome', 'acao']
  displayedColumnsCursoSem = ['nome', 'acao']

  dataSourceCursoCom: MatTableDataSource<any>;
  dataSourceCursoSem: MatTableDataSource<any>;

  @ViewChild(MatSort) sortCursoCom: MatSort;
  @ViewChild(MatSort) sortCursoSem: MatSort;

  cursosCom: any[] = []

  id: number = undefined
  
  constructor(private cursoService : CursoService,
              private fb: FormBuilder,
              private router: Router,
              private cepService: CepService,
              private utilService: UtilService,
              private activatedRoute: ActivatedRoute) {
                
                this.id = this.activatedRoute.snapshot.params['id']==undefined ? undefined : this.activatedRoute.snapshot.params['id']

               }

  ngOnInit() {

    this.estados = this.cepService.listaEstados()

    this.cadastroForm = this.fb.group({
      nome: this.fb.control('', [Validators.required]),
      descricao: this.fb.control(''),
      uf: this.fb.control(''),
      cidade: this.fb.control(''),
      latlng: this.fb.control(''),
      vagas: this.fb.control('', [Validators.min(1)]),
      idCategoria: this.fb.control(''),
      salario: this.fb.control(''),
      idade: this.fb.control(''),
      status: this.fb.control('')
    })

    this.cursoService.obterCategoriasCombo().subscribe(retorno => {
      this.categoria = retorno
    })

    if(this.id!=undefined){
      this.load()
    } else {
      this.cadastroForm.patchValue({
        status : true
      })
    }
    
    this.loadCursos(0)

  }
 
  doFilterCursoCom(filterValue:string){
    this.dataSourceCursoCom.filter = filterValue.trim().toLocaleLowerCase()
  }

  doFilterCursoSem(filterValue:string){
    this.dataSourceCursoSem.filter = filterValue.trim().toLocaleLowerCase()
  }
    
  excluirCurso(id: number){
        
    swal({
      title: 'Removendo...'
    });
    swal.showLoading();

    let index:number = this.cursosCom.findIndex(result=> result === id)

    this.cursosCom.splice(index,1)
    if(this.cursosCom.length==0){
      this.loadCursos('-1')  
    }else{
      this.loadCursos(this.cursosCom)  
    }
  }
  
  gravar(concurso : Concurso){

    if(!concurso.salario){
      concurso.salario = 0
    }

    if(!concurso.idade){
      concurso.idade = 0
    }

    if(this.id == undefined){
      this.save(concurso)
    }else{
      this.update(concurso)
    }
  }
    
  incluirCurso(id: number){
        
    swal({
      title: 'Incluindo...'
    });
    swal.showLoading();

    this.cursosCom.push(id)
    this.loadCursos(this.cursosCom)  
  }
  
  limpar(formDirective: FormGroupDirective){
    
    this.id=undefined
    
    formDirective.resetForm()
    this.cadastroForm.reset()
    
    this.cadastroForm.patchValue({
      visibilidade: 'E',
      completo: 'S',
      status : true
    })

    this.cursosCom=[]
    this.loadCursos(0)
    this.inputNome.nativeElement.focus()
  }

  load(){
    this.cursoService.obterConcursoId(this.id).subscribe(result => {
      
      this.cadastroForm.patchValue({
        id: result.id,
        nome: result.nome,
        descricao: result.descricao,
        uf: result.uf,
        cidade: result.cidade,
        latlng: result.latlng,
        vagas: result.vagas,
        idCategoria: result.idCategoria,
        salario: result.salario,
        idade: result.idade,
        status: result.status == "A"? true : false
      })

      this.retornaCidade(result.uf)
      
    })
  }

  loadCursos(com: any){

    this.cursoService.obterCursoSemConcurso(this.id == undefined? 0: this.id, com).subscribe(result => {
      this.dataSourceCursoSem = new MatTableDataSource(result);
      this.dataSourceCursoSem.sort = this.sortCursoSem;
      swal.close()
    })

    this.cursoService.obterCursoConcurso(this.id == undefined? 0: this.id, com).subscribe(result => {
      this.dataSourceCursoCom = new MatTableDataSource(result);
      this.dataSourceCursoCom.sort = this.sortCursoCom;
      if(this.cursosCom.length==0){
        result.forEach(element => {
          this.cursosCom.push(element.id)        
        }); 
      }      
    })
  }

  retornaCidade(uf:any){
    this.utilService.retornaCidades(uf).subscribe(result=>{
      this.cidades=result.cidades
    })
  }
  
  save(concurso : Concurso){

    this.cursoService.createConcurso(concurso).subscribe(result=>{
      
      this.id = result.id 

      this.cursoService.createCursoConcurso(this.id, this.cursosCom).subscribe(result=>{
        swal({
          title: 'Potencial Global',
          text: 'Concurso cadastrado com sucesso!',
          type: 'success',
          showConfirmButton: false,
          timer: 1500
        })

        // this.cadastroForm.reset()
      })

    },(error)=>{
      swal({
        title: 'Potencial Global',
        text: 'Ocorreu um erro no cadastro de cursos.',
        type: 'warning',
        showConfirmButton: false,
        timer: 2000
      })
    })
  }

  update(concurso: Concurso) {

    concurso.status = concurso.status.toString() == 'true' ? 'A' : 'D'
    concurso.id = this.id

    this.cursoService.updateConcurso(concurso).subscribe(result=>{

      this.cursoService.createCursoConcurso(this.id, this.cursosCom).subscribe(result=>{
        swal({
          title: 'Potencial Global',
          text: 'Concurso alterado com sucesso!',
          type: 'success',
          showConfirmButton: false,
          timer: 2000
        })

        setTimeout( () => {
            this.router.navigate(['/dashboard/cursos/concursos'])
          }, 2000 );
      })
    },
    (error: HttpErrorResponse)=>{
    })
  }
}