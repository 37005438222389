import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { Router } from '@angular/router';
import { Professor } from './professor.model';
import swal from 'sweetalert2';
import { ProfessorService } from './professor.service';

@Component({
  selector: 'app-professor',
  templateUrl: './professor.component.html',
  styleUrls: ['./professor.component.scss']
})
export class ProfessorComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  
  constructor(public professorService : ProfessorService,
              private router: Router) { }

  displayedColumns = ['status', 'nome', 'email', 'acao']
  dataSource: MatTableDataSource<Professor>;

  ngOnInit() {
    this.load()
  }

  delete(id){

    swal({
      title: 'Potencial Global',
      text: 'Deseja realmente excluir esse professor?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: "#DD6B55",
      confirmButtonText: "Sim",
      cancelButtonText: "Cancelar"
    })
    .then(retorno => {
      if (retorno.value) {    
        this.professorService.delete(id).subscribe(res=>{
          this.load()
        })
      }
    })

  }

  doFilter(filterValue:string){
    this.dataSource.filter = filterValue.trim().toLocaleLowerCase()
  }

  load(){

    swal({
      title: 'Carregando dados'
    });
    swal.showLoading();

    this.professorService.obterProfessoresListaGrid()
    .subscribe(retorno => {

      this.dataSource = new MatTableDataSource(retorno);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      swal.close()
    
    })
  }

  update(idProfessor: number){
    this.router.navigate(['dashboard/professores/form',idProfessor]);
  }

}