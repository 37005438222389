import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormGroupDirective } from '@angular/forms';
import { Aluno } from '../aluno.model';
import { Router, ActivatedRoute } from '@angular/router';
import { AlunoService } from '../aluno.service';
import swal from 'sweetalert2';
import { CepService } from 'src/app/shared/cep/cep.service';
import { HttpErrorResponse } from '@angular/common/http';
import * as moment from 'moment';
import { UtilService } from 'src/app/shared/util/util.service';

@Component({
  selector: 'app-formaluno',
  templateUrl: './formaluno.component.html',
  styleUrls: ['./formaluno.component.scss']
})

export class FormalunoComponent implements OnInit {
  
  @ViewChild(FormGroupDirective) alunoForm : FormGroup
  
  @ViewChild('cpf') inputCPF:ElementRef;
  @ViewChild('email') inputEmail:ElementRef;
  
  alunos: Aluno[]

  idAlunoTurma : number
  idAluno : number = undefined

  logradouro : string = null
  bairro : string = null
  cidade : string = null
  uf : string = null
  
  nascimento: any 

  constructor(private alunoService: AlunoService,
              private cepService: CepService,
              private utilService: UtilService,
              private fb: FormBuilder,
              private router: Router,
              private activatedRoute: ActivatedRoute) { 

                this.idAluno = this.activatedRoute.snapshot.params['id']==undefined ? undefined : this.activatedRoute.snapshot.params['id']

              }

  ngOnInit() {
    this.alunoForm = this.fb.group({
      id: this.fb.control(''),
      nome: this.fb.control('', [Validators.required]),
      cpf: this.fb.control('',[Validators.required]),
      email: this.fb.control('',[Validators.required, Validators.email]),
      sexo: this.fb.control('',[Validators.required]),
      telefone: this.fb.control('',[Validators.required, Validators.minLength(10)]),  
      cep: this.fb.control(''),
      complemento: this.fb.control(''),
    })

    this.getAluno(this.idAluno)

  }
  
  buscaCEP(cepBusca:string){

    this.cepService.buscaCEP(cepBusca).subscribe(result => {
      if(result.cep!= undefined){
        this.logradouro = result.logradouro
        this.bairro = result.bairro
        this.cidade = result.localidade
        this.uf = result.uf
      } else {
        swal({
          title: 'Potencial Global',
          text: 'Cep incorreto!',
          type: 'warning',
          showConfirmButton: false,
          timer: 2000
        })
        this.alunoForm.patchValue({
          cep: ""
        })
      }
    })
  }  
  
  getAluno(idAluno: number){

    if(idAluno) {

      this.alunoService.obterAlunoId(idAluno).subscribe(result => {

        this.logradouro = result[0].logradouro == undefined ? null : result[0].logradouro
        this.bairro = result[0].bairro == undefined ? null : result[0].bairro
        this.cidade = result[0].cidade == undefined ? null : result[0].cidade
        this.uf = result[0].uf == undefined ? null : result[0].uf

        this.idAlunoTurma = result[0].idAlunoTurma

        this.alunoForm.patchValue({
          id: result[0].id,
          nome: result[0].nome,
          cpf: result[0].cpf,
          email: result[0].email,
          sexo: result[0].sexo,          
          telefone: result[0].telefone,
          cep: result[0].cep,
          logradouro : this.logradouro == undefined ? null : this.logradouro,
          complemento: result[0].complemento == undefined ? null : result[0].complemento,
          bairro : this.bairro == undefined ? null : this.bairro,
          cidade : this.cidade == undefined ? null : this.cidade,
          uf : this.uf == undefined ? null : this.uf,        
        })

        this.nascimento = moment(result[0].nascimento).add(1,'days').format('YYYY-MM-DD')
      })

    }
  }

  gravar(aluno : Aluno, formDirective: FormGroupDirective){
    let formataNascimento = new Date(moment(this.nascimento).add(-1,'days').format('YYYY-MM-DD'))
    aluno.nascimento = formataNascimento

    if(this.idAluno == undefined){
      this.save(aluno, formDirective)
    }else{
      this.update(aluno)
    }
  }

  listaCEP(cep: string){
    
    if(cep!=null){
      if(cep.length==8){
        this.buscaCEP(cep)
      }else{
        this.logradouro = null
        this.bairro = null
        this.cidade = null
        this.uf = null
      }
    }else{
      this.logradouro = null
      this.bairro = null
      this.cidade = null
      this.uf = null
    }
  }

  save(aluno : Aluno, formDirective: FormGroupDirective){

    aluno.logradouro = this.logradouro
    aluno.bairro = this.bairro
    aluno.cidade = this.cidade
    aluno.uf = this.uf
    aluno.username = null
    
    this.alunoService.create(aluno).subscribe(result=>{
      swal({
        title: 'Potencial Global',
        text: 'Aluno cadastrado com sucesso!',
        type: 'success',
        showConfirmButton: false,
        timer: 1500
      })

      formDirective.resetForm();
      this.alunoForm.reset()

      this.logradouro = null
      this.bairro = null
      this.cidade = null
      this.uf = null

    },(error)=>{
      swal({
        title: 'Potencial Global',
        text: 'Ocorreu um erro no cadastro de alunos.',
        type: 'warning',
        showConfirmButton: false,
        timer: 2000
      })
    })
  }

  setaCEPContratante(alunoForm: any){
    if(this.logradouro==null){
      swal({
        title: 'Potencial Global',
        text: 'Informe um CEP válido!',
        type: 'error',
        showConfirmButton: false,
        timer: 1500
      })
    } else if(alunoForm.complemento =="") {
      swal({
        title: 'Potencial Global',
        text: 'Informe o complemento!',
        type: 'error',
        showConfirmButton: false,
        timer: 1500
      })
    } else {
      swal({
        title: 'Potencial Global',
        text: 'Deseja realmente colocar esse CEP para o contratate?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: "#DD6B55",
        confirmButtonText: "Sim",
        cancelButtonText: "Cancelar"
      })
      .then(retorno => {
        if (retorno.value) {
          this.alunoForm.patchValue({
            cepContratante: alunoForm.cep,
            complementoContratante: alunoForm.complemento
          })
        }
      })
    }
  }

  setaContratante(alunoForm: any, campo:number){

    let erroContratante = false
    
    if(campo==1){
      if(alunoForm.filiacao1=='' || alunoForm.telefoneFiliacao1==''){
        erroContratante=true
      }
    }else{
      if(alunoForm.filiacao2=='' || alunoForm.telefoneFiliacao2==''){
        erroContratante=true
      }
    }

    if(erroContratante){
      swal({
        title: 'Potencial Global',
        text: 'Todos os campos do Contratante devem ser preenchidos!',
        type: 'error',
        showConfirmButton: false,
        timer: 1500
      })
    } else {

      swal({
        title: 'Potencial Global',
        text: 'Deseja realmente colocar esses dados como contratate?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: "#DD6B55",
        confirmButtonText: "Sim",
        cancelButtonText: "Cancelar"
      })
      .then(retorno => {
        if (retorno.value) {
          if(campo==1){
            this.alunoForm.patchValue({
              contratante: alunoForm.filiacao1,
              telefoneContratante: alunoForm.telefoneFiliacao1
            })
          }else{
            this.alunoForm.patchValue({
              contratante: alunoForm.filiacao2,
              telefoneContratante: alunoForm.telefoneFiliacao2
            })
          }
        }
      })
    }
  }

  setarNascimento(dataNascimento: any){

    if(dataNascimento.value==undefined || dataNascimento.value==null){
      this.nascimento = ''
    }else{
      let dataN = dataNascimento.value.toString()
      dataN = moment(dataN).format("YYYY") + "-" + moment(dataN).format("DD") + "-" + moment(dataN).format("MM")
      this.nascimento = moment(dataN).add(1,'days').format('YYYY-MM-DD')
    }

  }

  update(aluno: Aluno) {

    aluno.logradouro = this.logradouro
    aluno.bairro = this.bairro
    aluno.cidade = this.cidade
    aluno.uf = this.uf
    this.alunoService.update(aluno).subscribe(result=>{

      swal({
        title: 'Potencial Global',
        text: 'Aluno alterado com sucesso!',
        type: 'success',
        showConfirmButton: false,
        timer: 2000
      })

      setTimeout( () => {
          this.router.navigate(['/dashboard/alunos'])
        }, 2000 );
      },(error: HttpErrorResponse)=>{
    })
  }

  validaCPF(cpf: string){

    if(cpf!=''){
      if(!this.utilService.validarCPF(cpf)){
        swal({
          title: 'Potencial Global',
          text: 'CPF inválido!',
          type: 'info',
          showConfirmButton: false,
          timer: 2000
        })
        setTimeout( () => {
          this.alunoForm.patchValue({
            cpf: ""
          })
          this.inputCPF.nativeElement.focus()
        }, 2001 );
      } else {
        this.alunoService.obterAlunoCPF(cpf).subscribe(result=>{
          if(result.length>0){
            if(result[0].id!=this.idAluno){
              swal({
                title: 'Potencial Global',
                text: 'CPF já cadastrado!',
                type: 'info',
                showConfirmButton: false,
                timer: 2000
              })
              setTimeout( () => {
                this.alunoForm.patchValue({
                  cpf: ""
                })
                this.inputCPF.nativeElement.focus()
              }, 2001 );
            }
          }
        })
      }
    }

  }

  validaEmail(email: string){
    this.alunoService.obterAlunoEmail(email).subscribe(result=>{
      if(result.length>0){
        if(result[0].id!=this.idAluno){
          swal({
            title: 'Potencial Global',
            text: 'Email já cadastrado!',
            type: 'info',
            showConfirmButton: false,
            timer: 2000
          })
          setTimeout( () => {
            this.alunoForm.patchValue({
              email: ""
            })
            this.inputEmail.nativeElement.focus()
          }, 2001 );
        }
      }
    })
  }
}