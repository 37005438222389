import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroupDirective, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { EmpresaService } from '../empresa.service';
import { Empresa } from '../empresa.model';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import swal from 'sweetalert2';
import { HttpErrorResponse } from '@angular/common/http';
import { BACKEND_FILE } from '../../app.api'

@Component({
  selector: 'app-formempresa',
  templateUrl: './formempresa.component.html',
  styleUrls: ['./formempresa.component.scss']
})
export class FormempresaComponent implements OnInit {

  @ViewChild(FormGroupDirective) cadastroForm: FormGroup
  imageChangedEvent: any
  croppedImage: any = ''
  empresaId: any

  constructor(private empresaService: EmpresaService,
    private fb: FormBuilder,
    public dialog: MatDialog,
    private router: Router,
    private activatedRoute: ActivatedRoute) { 
      this.empresaId = this.activatedRoute.snapshot.params['id']==undefined ? undefined : this.activatedRoute.snapshot.params['id']
    }

    ngOnInit() {
      
      this.cadastroForm = this.fb.group({
        nome: this.fb.control('', [Validators.required]),
        nif: this.fb.control('', [Validators.required]),
      })

      this.load(this.empresaId)
    }
    gravar(empresa : Empresa){  
      if(this.empresaId == undefined){
        this.save(empresa)
      }else{
        this.update(empresa)
      }
    }
    save(empresa : Empresa){

      this.empresaService.createEmpresa(empresa, this.imageChangedEvent).subscribe(result=>{
          swal({
            title: 'Potencial Gobal',
            text: 'Curso cadastrado com sucesso!',
            type: 'success',
            showConfirmButton: false,
            timer: 1500
          })
      },(error)=>{
        swal({
          title: 'Potencial Global',
          text: 'Ocorreu um erro no cadastro de cursos.',
          type: 'warning',
          showConfirmButton: false,
          timer: 2000
        })
      })
    }

    fileChangeEvent(events: any): void {
        this.imageChangedEvent = events.target;
        if (events.target.files && events.target.files[0]) {
          var reader = new FileReader();

          reader.onload = (event:any) => {
            this.croppedImage = event.target.result;
          }

          reader.readAsDataURL(events.target.files[0]);
      }
    }

    load(empresaId){
      this.empresaService.obterEmpresasId(empresaId).subscribe((empresa:any)=>{
        this.cadastroForm.patchValue({
          nome: empresa.nome,
          nif: empresa.nif
        })
        this.croppedImage = `${BACKEND_FILE}/${empresa.logo}`
      })
    }

    imageCropped(event: ImageCroppedEvent) {
      this.croppedImage = event.base64;
    }
    
    imageLoaded() {
        // show cropper
    }

    cropperReady() {
        // cropper ready
    }

    loadImageFailed() {
        // show message
    }

    upload(){

      // this.professorService.uploadProfessorFoto(this.croppedImage, this.data.id).subscribe(result=>{

      // },(error)=>{
      //   console.log('error=>', error)
      // })
      // this.onNoClick()
    }


  update(empresa:any) {
    let dados = empresa
    dados.id = this.activatedRoute.snapshot.params['id']
    this.empresaService.updateEmpresa(dados, this.imageChangedEvent).subscribe(result=>{
      
          swal({
            title: 'Potencial Global',
            text: 'Empresa alterado com sucesso!',
            type: 'success',
            showConfirmButton: false,
            timer: 2000
          })

          setTimeout( () => {
              this.router.navigate(['/dashboard/empresas'])
            }, 2000 );
        
      },(error: HttpErrorResponse)=>{})
  }

}
