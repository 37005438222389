import { NgModule  } from "@angular/core" 
import { BrowserModule } from "@angular/platform-browser" 
import { BrowserAnimationsModule } from '@angular/platform-browser/animations' 
import { FormsModule, ReactiveFormsModule } from "@angular/forms" 
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http" 
import { RouterModule } from "@angular/router" 
import { ROUTES } from "./app.routes" 
import { LocationStrategy, HashLocationStrategy } from "@angular/common" 
import { CURRENCY_MASK_CONFIG, CurrencyMaskConfig } from "ng2-currency-mask/src/currency-mask.config"
import { CurrencyMaskModule} from "ng2-currency-mask" 
import { FlexLayoutModule } from '@angular/flex-layout' 
import { AppComponent } from "./app.component" 
import { AlterarsenhaComponent } from "./security/alterarsenha/alterarsenha.component" 
import { AuthInterceptor } from "./security/auth.interceptor" 
import { DashboardComponent } from "./dashboard/dashboard.component" 
import { FooterComponent } from "./footer/footer.component" 
import { HeaderComponent } from "./header/header.component" 
import { HomeComponent } from "./home/home.component" 
import { LoginComponent } from "./security/login/login.component" 
import { MenuComponent } from "./menu/menu.component" 
import { NotFoundComponent } from "./not-found/not-found.component" 
import { RedefinirsenhaComponent } from "./security/redefinirsenha/redefinirsenha.component" 
import { UsuarioComponent } from "./usuario/usuario.component" 
import { NgxPaginationModule } from 'ngx-pagination'
import { NgxMaskModule } from 'ngx-mask'
import { CKEditorModule } from 'ng2-ckeditor' 
import { EmbedVideo } from 'ngx-embed-video' 
import 'hammerjs' 

//---=== Services ===---
import { LoginService } from "./security/login/login.service" 
import { LoggedInGuard } from "./security/loggedin.guard" 
import { EsqueciSenhaComponent } from "./security/esqueci-senha/esqueci-senha.component" 
import { LayoutModule } from "@angular/cdk/layout" 
import { MaterialModule } from "./material.module" 
import { UtilService } from "./shared/util/util.service" 
import { NumeroService } from "./shared/numero/numero.service" 
import { CepService } from "./shared/cep/cep.service" 
import { AlunoComponent } from './aluno/aluno.component' 
import { FormalunoComponent } from './aluno/formaluno/formaluno.component' 
import { AlunoService } from "./aluno/aluno.service" 
import { MAT_DATE_LOCALE, MatPaginatorIntl } from "@angular/material" 
import { CropimageComponent } from './cropimage/cropimage.component' 
import { getPaginatorIntl } from "./paginator" 
import { AboutComponent } from './about/about.component' 
import { EmailmensalidadeComponent } from './email/emailmensalidade/emailmensalidade.component' 
import { EmailvencimentoComponent } from './email/emailvencimento/emailvencimento.component' 
import { EmailinadimplentesComponent } from './email/emailinadimplentes/emailinadimplentes.component' 
import { EmailService } from "./email/email.service" 
import { ProfessorComponent } from './professor/professor.component' 
import { FormprofessorComponent, UploadProfessorFoto } from './professor/formprofessor/formprofessor.component' 
import { UsuarioService } from "./usuario/usuario.service" 
import { FormusuarioComponent } from './usuario/formusuario/formusuario.component' 
import { ProfessorService } from "./professor/professor.service" 
import { CursoComponent } from './curso/curso.component' 
import { CategoriaComponent } from './curso/categoria/categoria.component' 
import { SubcategoriaComponent } from './curso/subcategoria/subcategoria.component' 
import { FormcursoComponent, UploadCursoFoto } from './curso/formcurso/formcurso.component' 
import { FormcategoriaComponent } from "./curso/categoria/formcategoria/formcategoria.component"
import { FormsubcategoriaComponent } from "./curso/subcategoria/formsubcategoria/formsubcategoria.component"
import { CursoService } from "./curso/curso.service"
import { ConcursoComponent } from './curso/concurso/concurso.component'
import { CapituloComponent } from './curso/capitulo/capitulo.component'
import { ModuloComponent } from './curso/modulo/modulo.component'
import { FormmoduloComponent, UploadModuloFoto } from './curso/modulo/formmodulo/formmodulo.component'
import { FormcapituloComponent, UploadVimeo, UploadArquivo, PlayVideo } from './curso/capitulo/formcapitulo/formcapitulo.component'
import { UploadComponent } from './curso/upload/upload.component'
import { BannerComponent, UploadBannerDialog } from './banner/banner.component'
import { BannerService } from "./banner/banner.service"
import { ImageCropperModule } from 'ngx-image-cropper'
import { DragDropModule } from "@angular/cdk/drag-drop"
import { NgxHmCarouselModule } from 'ngx-hm-carousel'
import { TabsComponent } from './cursos/tabs/tabs.component'
import { FormconcursoComponent } from "./curso/concurso/formconcurso/formconcurso.component"
import { SafePipe } from './safe.pipe'
import { GeradorAcessoComponent } from './aluno/gerador-acesso/gerador-acesso.component'
import { EmpresasComponent } from './empresas/empresas.component'
import { EmpresaService }  from './empresas/empresa.service'
import { FormempresaComponent } from './empresas/formempresa/formempresa.component'
import { ListaAcessosComponent } from './aluno/lista-acessos/lista-acessos.component'
import { QuestionsComponent } from './curso/questions/questions.component'
import { FormquestionsComponent, UploadPerguntaFoto } from './curso/questions/formquestions/formquestions.component'
import { QuestionsService } from "./curso/questions/questions.service";


export const CustomCurrencyMaskConfig: CurrencyMaskConfig = {
  align: "left",
  allowNegative: false,
  decimal: ",",
  precision: 2,
  prefix: "",
  suffix: "",
  thousands: "."
};

@NgModule({
  entryComponents: [PlayVideo, UploadBannerDialog, UploadArquivo, UploadCursoFoto, UploadModuloFoto, UploadProfessorFoto, UploadVimeo, UploadPerguntaFoto],
  declarations: [
    AlterarsenhaComponent,
    AppComponent,
    DashboardComponent,
    EsqueciSenhaComponent,
    FooterComponent,
    HeaderComponent,
    HomeComponent,
    LoginComponent,
    MenuComponent,
    NotFoundComponent,
    PlayVideo,
    RedefinirsenhaComponent,     
    UsuarioComponent,
    AlunoComponent,
    FormalunoComponent,
    CropimageComponent,
    AboutComponent,
    EmailmensalidadeComponent,
    EmailvencimentoComponent,
    EmailinadimplentesComponent,
    ProfessorComponent,
    FormprofessorComponent,
    FormusuarioComponent,
    CursoComponent,
    CategoriaComponent,
    SubcategoriaComponent,
    FormcursoComponent,
    FormcategoriaComponent,
    FormsubcategoriaComponent,
    ConcursoComponent,
    FormconcursoComponent,
    CapituloComponent,
    ModuloComponent,
    FormmoduloComponent,
    FormcapituloComponent,
    UploadBannerDialog,
    UploadComponent,
    BannerComponent,
    UploadArquivo,
    UploadCursoFoto,
    UploadModuloFoto,
    UploadProfessorFoto,
    UploadVimeo,
    TabsComponent,
    SafePipe,
    GeradorAcessoComponent,
    EmpresasComponent,
    FormempresaComponent,
    ListaAcessosComponent,
    QuestionsComponent,
    FormquestionsComponent,
    UploadPerguntaFoto,
    
    
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CurrencyMaskModule,
    DragDropModule,
    EmbedVideo.forRoot(),
    FlexLayoutModule,
    FormsModule,
    ImageCropperModule,
    MaterialModule,
    NgxHmCarouselModule,
    NgxMaskModule.forRoot(),
    NgxPaginationModule,
    HttpClientModule,
    LayoutModule,
    ReactiveFormsModule,
    RouterModule.forRoot(ROUTES),
    CKEditorModule
  ],
  providers: [
    {provide: LocationStrategy, useClass: HashLocationStrategy},
    {provide: MAT_DATE_LOCALE, useValue: 'pt-BR'},
      AlunoService, BannerService, CepService, CursoService, EmailService, EmpresaService,
      LoggedInGuard, LoginService, NumeroService, ProfessorService,
      UsuarioService, UtilService, QuestionsService,  
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: CURRENCY_MASK_CONFIG, useValue: CustomCurrencyMaskConfig },
    { provide: MatPaginatorIntl, useValue: getPaginatorIntl() }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }