import { Injectable, Injector } from '@angular/core'
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpErrorResponse} from '@angular/common/http'
import { Observable } from 'rxjs'
import { catchError, tap } from 'rxjs/operators'

import { LoginService } from './login/login.service'
import { Usuario } from './login/usuario.model'
import { Router } from '@angular/router'

@Injectable()
export class AuthInterceptor implements HttpInterceptor{

    constructor(private injector: Injector, private route: Router){}
    intercept(request: HttpRequest<any>, next:HttpHandler): Observable<HttpEvent<any>>{

        const loginService = this.injector.get(LoginService)

        
        if(loginService.isLoggedIn()){         
            const authRequest = request.clone({headers: request.headers.set('Authorization', `${loginService.usuario().accessToken}`)})
            return next.handle(authRequest).pipe(
                tap((event: HttpEvent<any>) => {
                  if (event instanceof HttpResponse) {
         
                  }
                }, (err: any) => {
                  if (err instanceof HttpErrorResponse) {
                    if (err.status === 403) {
                        loginService.logout()
                    }
                  }
                }))
        } else { 
            return next.handle(request)
        }
    }
}