import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormGroupDirective } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import swal from 'sweetalert2';
import { Categoria } from '../../curso.model';
import { CursoService } from '../../curso.service';


@Component({
  selector: 'app-formcategoria',
  templateUrl: './formcategoria.component.html',
  styleUrls: ['./formcategoria.component.scss']
})
export class FormcategoriaComponent implements OnInit {

  @ViewChild(FormGroupDirective) cadastroForm: FormGroup
  categoria: Categoria

  id: number = undefined
  
  constructor(private cursoService: CursoService,
    private fb: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute) { 

      this.id = this.activatedRoute.snapshot.params['id']==undefined ? undefined : this.activatedRoute.snapshot.params['id']

    }

  ngOnInit() {
    this.cadastroForm = this.fb.group({
      id: this.fb.control(''),
      nome: this.fb.control('', [Validators.required]),
      descricao: this.fb.control(''),
      referencia: this.fb.control(''),
      status: this.fb.control('')
    })

    if(this.id!=undefined){
      this.load()
    }
  }

  load(){
    this.cursoService.obterCategoriaId(this.id).subscribe(result => {
      this.cadastroForm.patchValue({
        id: result.id,
        nome: result.nome,
        descricao: result.descricao,
        referencia: result.descricao,
        status: result.status == "A"? true : false
      })
    })
  }

  gravar(categoria : Categoria, formDirective: FormGroupDirective){
    if(this.id == undefined){
      this.save(categoria, formDirective)
    }else{
      this.update(categoria)
    }
  }

  save(categoria : Categoria, formDirective: FormGroupDirective){

    this.cursoService.createCategoria(categoria).subscribe(result=>{
      swal({
        title: 'Potencial Global',
        text: 'Categoria cadastrada com sucesso!',
        type: 'success',
        showConfirmButton: false,
        timer: 1500
      })

      formDirective.resetForm();
      this.cadastroForm.reset()

      },(error)=>{
      swal({
        title: 'Potencial Global',
        text: 'Ocorreu um erro no cadastro de capítulos.',
        type: 'warning',
        showConfirmButton: false,
        timer: 2000
      })
    })
  }

  update(categoria: Categoria) {

    categoria.status = categoria.status.toString() == 'true' ? 'A' : 'D'
    categoria.id = this.id

    this.cursoService.updateCategoria(categoria).subscribe(result=>{

    swal({
      title: 'Potencial Global',
      text: 'Categoria alterada com sucesso!',
      type: 'success',
      showConfirmButton: false,
      timer: 2000
    })

    setTimeout( () => {
        this.router.navigate(['/dashboard/cursos/categorias'])
    }, 2000 );
    },(error: HttpErrorResponse)=>{
    })
    }
  }