import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroupDirective, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AlunoService } from '../aluno.service';
import swal from 'sweetalert2';
import { EmpresaService } from 'src/app/empresas/empresa.service';
import { CursoService } from 'src/app/curso/curso.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-gerador-acesso',
  templateUrl: './gerador-acesso.component.html',
  styleUrls: ['./gerador-acesso.component.scss']
})
export class GeradorAcessoComponent implements OnInit {
  
  @ViewChild(FormGroupDirective) acessoForm : FormGroup
  empresas: any[]
  cursos: any[]
  alunos: any[]
  turma: any
  
  constructor(private fb: FormBuilder,
    private empresaService: EmpresaService,
    private alunoService: AlunoService,
    private cursosService: CursoService,
    private router: Router) { }

  ngOnInit() {
    this.acessoForm = this.fb.group({
      quantidadeAcesso: this.fb.control('', [Validators.required]),
      expirationDays: this.fb.control('', [Validators.required]),
      empresa: this.fb.control('', [Validators.required]),
      curso: this.fb.control('', [Validators.required])
    })
    this.loadEmpresa()
    this.loadCursos()
  }


  gravar(acessos : any, formDirective: FormGroupDirective){

    this.alunoService.createAcessos(acessos).subscribe(result=>{
      swal({
        title: 'Potencial Global',
        text: 'Acessos gerados com sucesso!',
        type: 'success',
        showConfirmButton: false,
        timer: 1500
      })
      this.turma = result.turma
      // formDirective.resetForm();
      this.acessoForm.reset()


    },(error)=>{
      swal({
        title: 'Potencial Global',
        text: 'Ocorreu um erro para gerar acesso.',
        type: 'warning',
        showConfirmButton: false,
        timer: 2000
      })
    })
  }

  loadEmpresa(){
    this.empresaService.obterEmpresas().subscribe(retorno => {
      this.empresas = retorno
    })

    }

    loadCursos(){
      this.cursosService.obterCursos().subscribe(cursos=>{
        this.cursos = cursos
      })
    }

    listaAcessos(turma){
      
      this.router.navigate(['dashboard/alunos/lista-acesso/',turma]);

    }
  }