import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss']
})
export class TabsComponent implements OnInit {
  
  routeLinks: any[];
  activeLinkIndex = -1;
  private rlaSafe: boolean = false;

  constructor() { 

    this.routeLinks = [
      {
          label: 'Cursos',
          link: './cursos',
          index: 0
      }, {
          label: 'Modulos',
          link: './cursos/modulos',
          index: 1
      }, {
          label: 'Çapitulos',
          link: './cursos/capitulos',
          index: 2
      }]
  }
  // private rlaSafe: boolean = false;
  public ngAfterViewInit() {
    this.rlaSafe = true;
  }

  ngOnInit() {
  }

}
