import { Component, OnInit, ViewChild } from '@angular/core';
import swal from 'sweetalert2';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { CursoService } from '../curso.service';
import { Router } from '@angular/router';
import { Subcategoria } from '../curso.model';

@Component({
  selector: 'app-subcategoria',
  templateUrl: './subcategoria.component.html',
  styleUrls: ['./subcategoria.component.scss']
})
export class SubcategoriaComponent implements OnInit {

  displayedColumns = ['status', 'nome', 'categoria', 'acao']
  dataSource: MatTableDataSource<Subcategoria>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(public cursoService : CursoService,
              private router: Router) { }

  ngOnInit() {
    this.load()
  }

  ngAfterViewInit(){
    if(this.dataSource!=undefined){
      this.dataSource.sort = this.sort
    }
  }

  doFilter(filterValue:string){
    this.dataSource.filter = filterValue.trim().toLocaleLowerCase()
  }

  load(){

    swal({
      title: 'Carregando dados'
    });
    swal.showLoading();

    this.cursoService.obterSubcategoriasGrid()
    .subscribe(retorno => {

      this.dataSource = new MatTableDataSource(retorno);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      swal.close()})
  }

  update(idAluno: number){
    this.router.navigate(['dashboard/cursos/subcategorias/form',idAluno]);
  }

}