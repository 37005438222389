import {Component, NgModule, OnInit, ViewChild, AfterViewInit} from '@angular/core'
import {Router} from '@angular/router'
import {BrowserModule} from '@angular/platform-browser'
import swal from 'sweetalert2';

import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { AlunoService } from './aluno.service';
import { AlunoGrid } from './aluno.model';

@NgModule({
  imports: [ BrowserModule]
})

@Component({
  selector: 'app-aluno',
  templateUrl: './aluno.component.html',
  styleUrls: ['./aluno.component.scss']
})
export class AlunoComponent implements OnInit, AfterViewInit {

  displayedColumns = ['status', 'nome', 'cpf', 'email', 'telefone', 'cadastro', 'acao']
  dataSource: MatTableDataSource<AlunoGrid>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(public alunoService : AlunoService,
              private router: Router) { }

  ngOnInit() {
    this.load() 
  }

  ngAfterViewInit(){
    if(this.dataSource!=undefined){
      this.dataSource.sort = this.sort
    }
  }
  
  delete(id){

    swal({
      title: 'Potencial Global',
      text: 'Deseja realmente excluir esse aluno?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: "#DD6B55",
      confirmButtonText: "Sim",
      cancelButtonText: "Cancelar"
    })
    .then(retorno => {
      if (retorno.value) {    
        this.alunoService.delete(id).subscribe(res=>{
          this.load()
        })
      }
    })

  }
  
  doFilter(filterValue:string){
    this.dataSource.filter = filterValue.trim().toLocaleLowerCase()
  }

  load(){

    swal({
      title: 'Carregando dados'
    });
    swal.showLoading();

    this.alunoService.obterAlunoListaGrid()
    .subscribe(retorno => {
      this.dataSource = new MatTableDataSource(retorno);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      swal.close()})
  }

  update(idAluno: number){
    this.router.navigate(['dashboard/alunos/form',idAluno]);
  }

}
