import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import swal from 'sweetalert2';
import { BACKEND_API } from '../../app.api';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AlterarSenha } from '../login/usuario.model';

@Component({
  selector: 'app-redefinirasenha',
  templateUrl: './redefinirsenha.component.html'
})
export class RedefinirsenhaComponent implements OnInit {

  link: string
  redefinirForm : FormGroup
  email : string
  exibindoMsg: boolean = false

  hideNova: boolean = true
  hideRepetir: boolean = true

  constructor(private http: HttpClient,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              private fb: FormBuilder) { 
                this.link = this.activatedRoute.snapshot.params['link']
              }

  ngOnInit() {
    this.redefinirForm = this.fb.group({
      new_password: this.fb.control(''),
      confirm_password: this.fb.control('')
    })

    this.http.get<any>(`${BACKEND_API}O/usuarios/validalink/${this.link}`) 
    .subscribe(res => {

      if(res.message=="Link inválido"){
        this.router.navigate(['/'])
      } else {
        this.email=res.message
      }
    })
  }

  updatePassword(alterarSenha: AlterarSenha){

    alterarSenha.email = this.email
    //alterarSenha.password = this.link

    this.http.put<any>(`${BACKEND_API}O/auth/resetuserpassword`, alterarSenha) 
      .subscribe(res => {

        if(res["message"] == "Senha alterada."){
          this.exibindoMsg = true
          swal({
            title: 'Potencial Global',
            text: 'Senha alterada!',
            type: 'success',
            showConfirmButton: false,
            timer: 1500
          })
          setTimeout( () => {
            this.router.navigate(['/'])
          }, 1500 );            
        }else {
          this.exibindoMsg = true
          swal({
            title: 'Potencial Global',
            text: res["message"],
            type: 'error',
            showConfirmButton: false,
            timer: 3000
          })  
          setTimeout( () => {
            this.exibindoMsg = false
          }, 3000 );  
        }
      })
  }  
}