import { Component, OnInit } from '@angular/core';
import { LoginService } from '../security/login/login.service';
import { Router } from '@angular/router';
import { Usuario } from '../security/login/usuario.model';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html'
})
export class DashboardComponent implements OnInit {

  loggedIn: boolean
  constructor( private loginService: LoginService, route: Router) {
    this.loggedIn = this.isloggedin()
   }
  
  ngOnInit() {
    console.log("Version: 2019/07/07")
  }

  isloggedin(){
    return this.loginService.isLoggedIn()
  }

  usuario(): Usuario {
    return this.loginService.usuario()
  }

  logout() {
    this.loginService.logout()
    return this.loginService.usuario()
  } 
}
