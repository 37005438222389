import { Component, OnInit } from '@angular/core';
import { AlunoService } from '../aluno.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-lista-acessos',
  templateUrl: './lista-acessos.component.html',
  styleUrls: ['./lista-acessos.component.scss']
})
export class ListaAcessosComponent implements OnInit {

  alunos=[]
  turma:any
  displayedColumns: string[] = ['Descricao', 'Token'];
  dataSource:any;
  constructor(private alunoService: AlunoService,
    private activatedRoute: ActivatedRoute) { 
    this.turma = this.activatedRoute.snapshot.params['turma']==undefined ? undefined : this.activatedRoute.snapshot.params['turma']
  }

  ngOnInit() {
    this.alunoService.obterAcessos(this.turma).subscribe(alunos=>{
      this.alunos = alunos
      this.dataSource = alunos
    })
  }

  print(){
    window.frames["print_frame"].document.body.innerHTML = document.getElementById("printableTable").innerHTML;
    window.frames["print_frame"].window.focus();
    window.frames["print_frame"].window.print();
    window.print()
  }


  
}
