import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { BACKEND_API } from "../../app.api";
import { Question } from "./question.model";

@Injectable()

export class QuestionsService{

    constructor(private http: HttpClient){}
    
    createQuestion(question: any): Observable<any>{
        return this.http.post<any>(`${BACKEND_API}/questions`, question)
        .pipe(map((result: any) => result))
    }
    
    obterQuestion(): Observable<any[]> {
        return this.http.get<any[]>(`${BACKEND_API}/questions`)
    }

    obterQuestionId(id : number): Observable<any> {
        return this.http.get<any>(`${BACKEND_API}/questions/${id}`)
    } 

    updateQuestion(question: any): Observable<any>{
        return this.http.put<any>(`${BACKEND_API}/questions/${question.id}`, question)
        .pipe(map((result: any) => result))
    }

    obterModuloSemPerguntas(id: number, perguntas: number): Observable<any[]> {
        return this.http.get<any[]>(`${BACKEND_API}/cursos/perguntasemmodulo/${id}/${perguntas}`)
    }

    obterModuloPerguntas(id: number, perguntas: number): Observable<any[]> {
        return this.http.get<any[]>(`${BACKEND_API}/cursos/perguntasmodulo/${id}/${perguntas}`)
    }

    createModuloPerguntas(perguntaId: number, perguntas: any): Observable<any[]> {
        return this.http.post<any[]>(`${BACKEND_API}/cursos/perguntaModulos/${perguntaId}`, perguntas)
    }
}