import { Component, OnInit, ViewChild } from '@angular/core';
import swal from 'sweetalert2';
import { MatSort, MatPaginator, MatTableDataSource } from '@angular/material';
import { Curso } from './curso.model';
import { Router } from '@angular/router';
import { CursoService } from './curso.service';

@Component({
  selector: 'app-curso',
  templateUrl: './curso.component.html',
  styleUrls: ['./curso.component.scss']
})
export class CursoComponent implements OnInit {

  displayedColumns = ['status', 'nome', 'referencia', 'cargaHoraria', 'acao']
  dataSource: MatTableDataSource<Curso>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(public cursoService : CursoService,
              private router: Router) { }

  ngOnInit() {
    this.load()
  }

  ngAfterViewInit(){
    if(this.dataSource!=undefined){
      this.dataSource.sort = this.sort
    }
  }

  doFilter(filterValue:string){
    this.dataSource.filter = filterValue.trim().toLocaleLowerCase()
  }

  load(){

    swal({
      title: 'Carregando dados'
    });
    swal.showLoading();

    this.cursoService.obterCursosGrid()
    .subscribe(retorno => {
      this.dataSource = new MatTableDataSource(retorno);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      swal.close()})
  }

  delete(id){

    swal({
      title: 'Potencial Global',
      text: 'Deseja realmente excluir esse curso?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: "#DD6B55",
      confirmButtonText: "Sim",
      cancelButtonText: "Cancelar"
    })
    .then(retorno => {
      if (retorno.value) {    
        this.cursoService.deleteCurso(id).subscribe(res=>{
          this.load()
        })
      }
    })

  }

  update(id: number){
    this.router.navigate(['dashboard/cursos/form',id]);
  }

}
