import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort, MatPaginator, MatTableDataSource } from '@angular/material';
import { Router } from '@angular/router';
import { Usuario } from './usuario.model';
import { UsuarioService } from './usuario.service';
import swal from 'sweetalert2';

@Component({
  selector: 'app-usuario',
  templateUrl: './usuario.component.html'
})
export class UsuarioComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  
  constructor(public usuarioService : UsuarioService,
              private router: Router) { }

  displayedColumns = ['status', 'nome', 'login', 'email', 'acao']
  dataSource: MatTableDataSource<Usuario>;

  ngOnInit() {
    this.load()
  }

  delete(id){

    swal({
      title: 'Potencial Global',
      text: 'Deseja realmente excluir esse usuário?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: "#DD6B55",
      confirmButtonText: "Sim",
      cancelButtonText: "Cancelar"
    })
    .then(retorno => {
      if (retorno.value) {    
        this.usuarioService.delete(id).subscribe(res=>{
          this.load()
        })
      }
    })

  }

  doFilter(filterValue:string){
    this.dataSource.filter = filterValue.trim().toLocaleLowerCase()
  }

  load(){

    swal({
      title: 'Carregando dados'
    });
    swal.showLoading();

    this.usuarioService.obterUsuariosListaGrid()
    .subscribe(retorno => {

      this.dataSource = new MatTableDataSource(retorno);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      swal.close()
    
    })
  }

  update(idFuncionario: number){
    this.router.navigate(['dashboard/usuarios/form',idFuncionario]);
  }

}