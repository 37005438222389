import { Component, OnInit, ViewChild, ElementRef, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormGroupDirective } from '@angular/forms';
import { CursoService } from '../../curso.service';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import swal from 'sweetalert2';
import { Modulo } from '../../curso.model';
import {  MatSort, MatTableDataSource, MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatPaginator } from '@angular/material';
import { BACKEND_FILE } from 'src/app/app.api';
import { DialogData } from 'src/app/banner/banner.component';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { QuestionsService } from '../../questions/questions.service';

export interface moduloFotoData {
  id: number;
}

@Component({
  selector: 'app-formmodulo',
  templateUrl: './formmodulo.component.html',
  styleUrls: ['./formmodulo.component.scss']
})
export class FormmoduloComponent implements OnInit {

  name = 'ng2-ckeditor';
  ckeConfig: any;
  log: string = '';
  @ViewChild("ckeDescricao") ckeditor: any;
 
  displayedColumnsPergunta = ['titulo']
  displayedColumnsCom = ['ordem','nome', 'acao']
  displayedColumnsSem = ['ordem','nome', 'acao']

  dataSourceCom: MatTableDataSource<any>;
  dataSourceSem: MatTableDataSource<any>;
  dataSourcePerguntas: MatTableDataSource<any>;

  @ViewChild(MatSort) sortCom: MatSort;
  @ViewChild(MatSort) sortSem: MatSort;

  displayedColumnsCursoCom = ['nome', 'acao']
  displayedColumnsCursoSem = ['nome', 'acao']

  dataSourceCursoCom: MatTableDataSource<any>;
  dataSourceCursoSem: MatTableDataSource<any>;

  @ViewChild(MatSort) sortCursoCom: MatSort;
  @ViewChild(MatSort) sortCursoSem: MatSort;

  capitulosCom: any[] = []
  cursosCom: any[] = []

  @ViewChild('nome') inputNome:ElementRef;
  @ViewChild(FormGroupDirective) cadastroForm: FormGroup

  //Perguntar relationadas com modulo
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  modulo: Modulo[]
  descricao: string

  public editorValue: string = '';
  foto: any

  questionId: number = undefined
  updateStatus: boolean = false

  modulosCom: any[] = []

  id : number = undefined

  changedSort: boolean = false

  constructor(private cursoService: CursoService,
              private fb: FormBuilder,
              private router: Router,
              public dialog: MatDialog,
              private activatedRoute: ActivatedRoute,
              private questionService: QuestionsService) { 

                this.id = this.activatedRoute.snapshot.params['id']==undefined ? undefined : this.activatedRoute.snapshot.params['id']

              }

  ngOnInit() {
        
    swal({
      title: 'Carregando dados'
    });
    swal.showLoading();

    this.ckeConfig = {
      allowedContent: false,
      extraPlugins: 'divarea',
      forcePasteAsPlainText: true
    };

    this.cadastroForm = this.fb.group({
      id: this.fb.control(''),
      nome: this.fb.control('', [Validators.required]),
      status: this.fb.control(''),
      ordem: this.fb.control('')
    })

    if(this.id!=undefined){
      this.load()
    } else {
      this.cadastroForm.patchValue({
        status : true
      })
    }

    this.loadCapitulos(0)
    this.loadCursos(0)
    this.loadPerguntas(this.id)
  }
 
  doFilterCom(filterValue:string){
    this.dataSourceCom.filter = filterValue.trim().toLocaleLowerCase()
  }

  doFilterSem(filterValue:string){
    this.dataSourceSem.filter = filterValue.trim().toLocaleLowerCase()
  }

  doFilterCursoCom(filterValue:string){
    this.dataSourceCursoCom.filter = filterValue.trim().toLocaleLowerCase()
  }

  doFilterCursoSem(filterValue:string){
    this.dataSourceCursoSem.filter = filterValue.trim().toLocaleLowerCase()
  }

  excluir(id: number){
        
    swal({
      title: 'Removendo...'
    });
    swal.showLoading();

    let index:number = this.capitulosCom.findIndex(result=> result === id)

    this.capitulosCom.splice(index,1)
    if(this.capitulosCom.length==0){
      this.loadCapitulos('-1')  
    }else{
      this.loadCapitulos(this.capitulosCom)  
    }
  }
  
  excluirCurso(id: number){
        
    swal({
      title: 'Removendo...'
    });
    swal.showLoading();

    let index:number = this.cursosCom.findIndex(result=> result === id)

    this.cursosCom.splice(index,1)
    if(this.cursosCom.length==0){
      this.loadCursos('-1')  
    }else{
      this.loadCursos(this.cursosCom)  
    }
  }

  incluir(id: number){
        
    swal({
      title: 'Incluindo...'
    });
    swal.showLoading();

    this.capitulosCom.push(id)
    this.loadCapitulos(this.capitulosCom)  
  }

  incluirCurso(id: number){
        
    swal({
      title: 'Incluindo...'
    });
    swal.showLoading();

    this.cursosCom.push(id)
    this.loadCursos(this.cursosCom)  
  }
  
  limpar(formDirective: FormGroupDirective){
    
    this.id=undefined
    
    formDirective.resetForm()
    this.cadastroForm.reset()
    
    this.cadastroForm.patchValue({
      visibilidade: 'E',
      completo: 'S',
      status : true,
      ordem: 0 
    })
    
    this.capitulosCom=[]
    this.cursosCom=[]
    this.descricao = ''
    this.loadCapitulos(0)
    this.loadCursos(0)
    this.inputNome.nativeElement.focus()

  }
  
  load(){
    this.cursoService.obterModuloId(this.id).subscribe(result => {
      this.cadastroForm.patchValue({
        id: result.id,
        nome: result.nome,
        ordem: result.ordem,
        status: result.status == "A"? true : false
      })
      this.descricao = result.descricao
    })
    this.retornarFoto()
  }

  loadCapitulos(com: any){

    this.cursoService.obterModuloSemCapitulo(this.id == undefined? 0: this.id, com).subscribe(result => {
      this.dataSourceSem = new MatTableDataSource(result);
      this.dataSourceSem.sort = this.sortSem;
      swal.close()
    })

    this.cursoService.obterModuloCapitulo(this.id == undefined? 0: this.id, com).subscribe(result => {
      this.dataSourceCom = new MatTableDataSource(result);
      this.dataSourceCom.sort = this.sortCom;
      if(this.capitulosCom.length==0){
        result.forEach(element => {
          this.capitulosCom.push(element.id)        
        }); 
      }      
    })
    
  }

  loadCursos(com: any){

    this.cursoService.obterCursoSemModulo(this.id == undefined? 0: this.id, com).subscribe(result => {
      this.dataSourceCursoSem = new MatTableDataSource(result);
      this.dataSourceCursoSem.sort = this.sortCursoSem;
      swal.close()
    })

    this.cursoService.obterCursoModulo(this.id == undefined? 0: this.id, com).subscribe(result => {
      this.dataSourceCursoCom = new MatTableDataSource(result);
      this.dataSourceCursoCom.sort = this.sortCursoCom;
      if(this.cursosCom.length==0){
        result.forEach(element => {
          this.cursosCom.push(element.id)        
        }); 
      }      
    })
  }

  
  loadPerguntas(moduloId: any){
    this.cursoService.obterCursoModuloPergunta(moduloId).subscribe(result => {
      this.dataSourcePerguntas = new MatTableDataSource(result[0].Perguntas);
      this.dataSourcePerguntas.paginator = this.paginator;
      this.dataSourcePerguntas.sort = this.sort;   
    })
  }

  gravar(modulo : Modulo){

    modulo.descricao = this.descricao

    if(this.id == undefined){
      this.save(modulo)
    }else{
      this.update(modulo)
    }
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(UploadModuloFoto, {
      width: '400px',
      height: '600px',
      data:{ id : this.id}
    });

    dialogRef.afterClosed().subscribe(result => {
      this.foto = undefined
      setTimeout( () => {
        this.retornarFoto()
      }, 3000 );
    });
  }

  retornarFoto(){
    this.cursoService.obterModuloFoto().subscribe(result=>{

      if(result){
        result.forEach(element => {
          let valida: string = element
          if(this.id.toString() == valida.substring(0,this.id.toString().length)){
            this.foto = BACKEND_FILE + "/foto/modulo/" + element
          }
        });
      }

    })
  }
  
  save(modulo : Modulo){

    this.cursoService.createModulo(modulo).subscribe(result=>{

      this.id = result.id 

      this.cursoService.createModuloCapitulo(result.id, this.capitulosCom).subscribe(result=>{
        this.cursoService.createModuloCurso(this.id, this.cursosCom).subscribe(result=>{
          swal({
            title: 'Potencial Global',
            text: 'Módulo cadastrado com sucesso!',
            type: 'success',
            showConfirmButton: false,
            timer: 1500
          })

          // this.cadastroForm.reset()
          // this.capitulosCom=[]
          // this.descricao = ''
          // this.loadCapitulos(0)
        })
      })
    },(error)=>{
      swal({
        title: 'Potencial Global',
        text: 'Ocorreu um erro no cadastro de módulos.',
        type: 'warning',
        showConfirmButton: false,
        timer: 2000
      })
    })
  }

  update(modulo: Modulo) {

    modulo.status = modulo.status.toString() == 'true' ? 'A' : 'D'
    modulo.id = this.id

    this.cursoService.updateModulo(modulo).subscribe(result=>{

      this.cursoService.createModuloCapitulo(this.id, this.capitulosCom).subscribe(result=>{

        this.cursoService.createModuloCurso(this.id, this.cursosCom).subscribe(result=>{

          swal({
            title: 'Potencial Global',
            text: 'Módulo alterado com sucesso!',
            type: 'success',
            showConfirmButton: false,
            timer: 2000
          })

          setTimeout( () => {
              this.router.navigate(['/dashboard/cursos/modulos'])
            }, 2000 );
        })
      })
    },
    (error: HttpErrorResponse)=>{
    
    })
  }

  moveDown(position,index){
    // this.dataSourceCom
    this.changedSort = true
    let newData: any[] = this.dataSourceCom.data
    newData.forEach(element => {
      if(element.ordem == (position)){
        newData[index].ordem = index +2
        newData[index+1].ordem = index +1       
      }
    });
    
    this.dataSourceCom.data = newData.sort((a, b) => parseFloat(a.ordem) - parseFloat(b.ordem));  
  }

  moveUp(position,index){
    this.changedSort = true
    let newData: any[] = this.dataSourceCom.data
    newData.forEach(element => {
      if(element.ordem == (position)){
        newData[index-1].ordem = index + 1
        newData[index].ordem = index 
      }
    });
    this.dataSourceCom.data = newData.sort((a, b) => a.ordem - b.ordem)
  }

  getLengthRows()
  {
    return this.dataSourceCom.data.length
  }  

  saveSort(){
    this.changedSort = false

    this.cursoService.updateSortConteudo(this.dataSourceCom.data).subscribe(result=>{
      swal({
          title: 'Potencial Global',
          text: 'Ordenação alterado com sucesso!',
          type: 'success',
          showConfirmButton: false,
          timer: 2000
        })

        // setTimeout( () => {
        //     this.router.navigate(['/dashboard/cursos'])
        // }, 2000 );
       
    }, (error)=>{
      swal({
        title: 'Potencial Global',
        text: 'Ocorreu um erro no cadastro da ordenação dos modulos.',
        type: 'warning',
        showConfirmButton: false,
        timer: 2000
      })
    })

    console.log(this.dataSourceCom.data)

  }


}

@Component({
  selector: 'uploadmodulofoto',
  templateUrl: './uploadmodulofoto.html',
})

export class UploadModuloFoto {
  imageChangedEvent: any = '';
  croppedImage: any = ''

  constructor(private cursoService: CursoService,
              public dialogRef: MatDialogRef<UploadModuloFoto>,
              @Inject(MAT_DIALOG_DATA) public data: moduloFotoData) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }
  imageLoaded() {
      // show cropper
  }
  cropperReady() {
      // cropper ready
  }
  loadImageFailed() {
      // show message
  }

  upload(){

    this.cursoService.uploadModuloFoto(this.croppedImage, this.data.id).subscribe(result=>{

    },(error)=>{
      console.log('error=>', error)
    })
    this.onNoClick()
  }
}