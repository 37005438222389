import { Component, OnInit, ViewChild, Inject, ElementRef } from '@angular/core';
import { Capitulo } from '../../curso.model';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CursoService } from '../../curso.service';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatTableDataSource, MatPaginator } from '@angular/material';
import { ProfessorService } from 'src/app/professor/professor.service';
import { EmbedVideoService } from 'ngx-embed-video';
import swal from 'sweetalert2';
import { GridAreaStyleBuilder } from '@angular/flex-layout/grid/typings/area/area';
import { BACKEND_FILE } from 'src/app/app.api';
import { DomSanitizer } from '@angular/platform-browser';

export interface DialogData {
  id: number;
  professores: any[];  
}

export interface DialogVideo{
  videoURL: string
}

@Component({
  selector: 'app-formcapitulo',
  templateUrl: './formcapitulo.component.html',
  styleUrls: ['./formcapitulo.component.scss']
})

export class FormcapituloComponent implements OnInit {

  cadastroForm: FormGroup

  displayedColumns = ['status', 'nome', 'nomeProfessor','cadastro', 'acao']
  dataSourceApostila: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  
  @ViewChild('nome') inputNome:ElementRef;

  name = 'ng2-ckeditor';
  ckeConfig: any;
  log: string = '';
  @ViewChild("ckeDescricao") ckeditor: any;
  
  capitulo: Capitulo[]

  apostilas: any[] = []
  videos: any[] = []

  professores: any[]
  descricao: string

  id : number = undefined

  constructor(private cursoService: CursoService,
              private professorService: ProfessorService,
              private fb: FormBuilder,
              private embedService: EmbedVideoService,
              private router: Router,
              public dialog: MatDialog,
              private activatedRoute: ActivatedRoute,
              private sanitizer: DomSanitizer) { 

                this.id = this.activatedRoute.snapshot.params['id']==undefined ? undefined : this.activatedRoute.snapshot.params['id']

                this.professorService.obterProfessoresCombo().subscribe(result=>{
                  this.professores = result
                })
              }

  ngOnInit() {
    this.cadastroForm = this.fb.group({
      id: this.fb.control(''),
      nome: this.fb.control('', [Validators.required]),
      referencia: this.fb.control(''),
      ordem: this.fb.control(''),
      status: this.fb.control('')
    })

    if(this.id!=undefined){
      this.load()
    }
  }
 
  deleteApostila(id:number){

    swal({
      title: 'Potencial Global',
      text: 'Deseja realmente excluir esse material?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: "#DD6B55",
      confirmButtonText: "Sim",
      cancelButtonText: "Cancelar"
    })
    .then(retorno => {
      if (retorno.value) {    
        this.cursoService.deleteArquivo(id, 'A').subscribe(result=>{
          this.loadApostilas()
        })
      }
    })
  }

  deleteVideo(id:number){

    swal({
      title: 'Potencial Global',
      text: 'Deseja realmente excluir esse vídeo?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: "#DD6B55",
      confirmButtonText: "Sim",
      cancelButtonText: "Cancelar"
    })
    .then(retorno => {
      if (retorno.value) {    
        this.cursoService.deleteArquivo(id, 'V').subscribe(result=>{
          this.loadVideos()
        })
      }
    })
  }

  load(){
    this.cursoService.obterCapituloId(this.id).subscribe(result => {
      this.cadastroForm.patchValue({
        id: result.id,
        nome: result.nome,
        referencia: result.referencia,
        ordem: result.ordem,
        status: result.status == "A"? true : false
      })
      this.descricao = result.descricao

      this.loadApostilas()
      this.loadVideos()
    })
  }

  loadApostilas(){
    this.cursoService.obterCapituloApostilas(this.id).subscribe(result=>{
      this.apostilas = result
      this.dataSourceApostila = new MatTableDataSource(result.length==0? undefined : result);
      this.dataSourceApostila.paginator = this.paginator;
    })
  }

  loadVideos(){
    this.cursoService.obterCapituloVideos(this.id).subscribe(result=>{

      this.videos = []

      result.forEach(element => {
        this.cursoService.obterVideos(element.link).subscribe(imagem =>{
          this.videos.push({"nome":element.nome, "imagem":imagem.pictures.sizes[0].link, "id":element.id, "duracao":element.duracao, "link": element.link})

        })
      });
      
    })
  }

  getUrlYoutube(url){
    let newUrl =  "https://www.youtube.com/embed/"
    let checkUrl = url.split("=")
    newUrl += checkUrl[1]
    return this.sanitizer.bypassSecurityTrustHtml(`<iframe width="300" height="225"  src=${newUrl} frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`)
   
  }

  gravar(capitulo : Capitulo){

    capitulo.descricao = this.descricao
    
    if(this.id == undefined){
      this.save(capitulo)
    }else{
      this.update(capitulo)
    }
  }

  novo(){
    this.id = undefined
    this.inputNome.nativeElement.focus()
    this.cadastroForm.reset()
  }

  openDialogArquivo(): void {
    const dialogRef = this.dialog.open(UploadArquivo, {
      width: '800px',
      height: '330px',
      disableClose: true,
      data:{id: this.id, professores : this.professores }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.loadApostilas()
      setTimeout( () => {
      }, 3000 );
    });
  }
  
  linkVideo(link:any){
    this.openDialogPlayer(link)
  }

  openDialogPlayer(url:string): void {
    
    const dialogRef = this.dialog.open(PlayVideo, {
      width: '301px',
      height: '222px',
      disableClose: true,
      data:{videoURL: url}
    });

    dialogRef.afterClosed().subscribe(result => {

    });
  }

  openDialogVimeo(): void {
    const dialogRef = this.dialog.open(UploadVimeo, {
      width: '800px',
      height: '355px',
      disableClose: true,
      data:{id: this.id, professores : this.professores }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.loadVideos()
      setTimeout( () => {
      }, 3000 );
    });
  }

  save(capitulo : Capitulo){

    this.cursoService.createCapitulo(capitulo).subscribe(result=>{
      this.id = (result.id)
      swal({
        title: 'Potencial Global',
        text: 'Capítulo cadastrado com sucesso!',
        type: 'success',
        showConfirmButton: false,
        timer: 1500
      })

      this.cadastroForm.patchValue({
        status: true
      })

      //this.cadastroForm.reset()

    },(error)=>{
      swal({
        title: 'Potencial Global',
        text: 'Ocorreu um erro no cadastro de capítulos.',
        type: 'warning',
        showConfirmButton: false,
        timer: 2000
      })
    })

  }

  update(capitulo: Capitulo) {

    capitulo.status = capitulo.status.toString() == 'true' ? 'A' : 'D'
    capitulo.id = this.id

    this.cursoService.updateCapitulo(capitulo).subscribe(result=>{

      swal({
        title: 'Potencial Global',
        text: 'Capítulo alterado com sucesso!',
        type: 'success',
        showConfirmButton: false,
        timer: 2000
      })

      setTimeout( () => {
          this.router.navigate(['/dashboard/cursos/capitulos'])
        }, 2000 );
      },(error: HttpErrorResponse)=>{
    })
  }

  vimeo(){
    this.router.navigate(['/dashboard/cursos/capitulos/upload/video/',this.id]);
  }

  openDoc(doc, tipo) {
    switch (tipo) {
      case "G":
        window.open(doc.conteudo, '_blank');
        break;
      case "A":
        window.open(`${BACKEND_FILE}/apostilas/${doc.link}`, '_blank');
        break;
      default:
        break;
    }
    
  }
}

@Component({
  selector: 'uploadarquivo',
  templateUrl: './uploadarquivo.html',
})

export class UploadArquivo {
  idProfessor: number
  professores: any[]
  nome: string

  description: String
  capitulo: String
  fileName: String 
  tipoArquivoConteudo: String 
  conteudo: String //Link googleform ou link toytube para depende do tipo de arquivo

  fileToUpload: File = null;
  tipoArquivo: any =[
  {nome:"Youtube", tipo:"Y"},
  {nome:"GoogleForm", tipo:"G"},
  {nome:"Arquivo", tipo:"A"},
  {nome:"Arquivo-Download", tipo:"D"},
  {nome:"Audio", tipo:"M"}
]

  executaUpload: boolean = true

  constructor(private cursoService: CursoService,
              public dialogRef: MatDialogRef<UploadArquivo>,
              @Inject(MAT_DIALOG_DATA) public data: DialogData) {
                  this.professores = data.professores
              }

  gravar(){

    if(this.executaUpload && this.tipoArquivoConteudo === "A"){
      swal({
        title: 'Carregando...',
        allowOutsideClick: false
      });
      swal.showLoading();
      this.cursoService.uploadApostila(this.fileToUpload, this.nome, this.idProfessor, this.data.id, this.fileName).subscribe(result=>{
        swal.close()
        let capituloConteudo = []
        capituloConteudo.push({"nome":this.nome, "idProfessor":this.idProfessor,"idCapitulo":this.data.id,"tipo":this.tipoArquivoConteudo,"link":this.fileName, "conteudo":this.conteudo})

        this.cursoService.createCapituloConteudo(capituloConteudo).subscribe(result=>{

          swal({
            title: 'Potencial Global',
            text: 'Conteudo cadastrada com sucesso!',
            type: 'success',
            showConfirmButton: false,
            timer: 2000
          })
          
          setTimeout( () => {
            this.onNoClick()
          }, 2000 );

        })
      },(error)=>{

        swal.close()
        swal({
          title: 'Potencial Global',
          text: 'Upload arquivo não realizado!',
          type: 'error',
          showConfirmButton: false,
          timer: 2000
        })
        
        setTimeout( () => {
          this.onNoClick()
        }, 2000 );

        console.log('error=>', error)
      })

    }else{
      
      let capituloConteudo = []
        capituloConteudo.push({"nome":this.nome, "idProfessor":this.idProfessor,"idCapitulo":this.data.id,"tipo":this.tipoArquivoConteudo,"link":this.fileName, "conteudo":this.conteudo})

        this.cursoService.createCapituloConteudo(capituloConteudo).subscribe(result=>{

          swal({
            title: 'Potencial Global',
            text: 'Conteudo cadastrada com sucesso!',
            type: 'success',
            showConfirmButton: false,
            timer: 2000
          })
          
          setTimeout( () => {
            this.onNoClick()
          }, 2000 );

        })

    }

  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  changeUpload(files: FileList){
    this.fileToUpload = files.item(0)
    this.fileName = this.fileToUpload.name

    this.cursoService.obterApostilas().subscribe(result=>{

      result.forEach(element => {
        if(element == this.fileName){
          
          swal({
            title: 'Potencial Global',
            text: 'Esse arquivo já foi importado. Deseja sobrescrever?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "Sim",
            cancelButtonText: "Não"
          })
          .then(retorno => {
            if(!retorno.value){
              this.executaUpload=false
            }else{
              this.executaUpload=true
            }
          })

        }
      });

    })
  }

  
}

@Component({
  selector: 'playvideo',
  templateUrl: './playvideo.html',
})

export class PlayVideo {

  videoURL: string

  constructor(public dialogRef: MatDialogRef<PlayVideo>,
              private embedService: EmbedVideoService,
              @Inject(MAT_DIALOG_DATA) public data: DialogVideo) {
                let iframeUrl = `https://player.vimeo.com/video/${data.videoURL}?badge=0&autopause=0&player_id=0&app_id=112950`
                
                  this.videoURL = `<iframe src="${iframeUrl}" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>`
                  
                  console.log(this.videoURL)
              }

  onNoClick(): void {
    this.dialogRef.close();
  }
}

@Component({
  selector: 'uploadvimeo',
  templateUrl: './uploadvimeo.html',
})

export class UploadVimeo {
  idProfessor: number
  professores: any[]
  nome: string
  duracao: any

  fileUpload : any
  description: String
  capitulo: String
  fileInput: any
  fileName: String  
  linkVideo: string
  videosCombo: any[] = []
  videoExistente: any

  constructor(private cursoService: CursoService,
              public dialogRef: MatDialogRef<UploadVimeo>,
              @Inject(MAT_DIALOG_DATA) public data: DialogData) {
                  this.professores = data.professores
                  this.cursoService.obterCapituloVideosCombo().subscribe(result=>{
                    this.videosCombo = result
                  })
              }

  onNoClick(): void {
    this.dialogRef.close();
  }

  changeUploadVimeo(event){
    this.fileUpload = event.target
    this.fileName = this.fileUpload.files[0].name
  }

  uploadVimeo(){

    swal({
      title: 'Carregando...',
      allowOutsideClick: false
    });
    swal.showLoading();

    if(this.videoExistente){

      let dados : any[] = []

      dados.push({'nome':this.nome, 'link': this.linkVideo, 'capituloId': this.data.id, 'idProfessor': this.idProfessor})

      this.cursoService.createVideo(dados).subscribe(result=>{
        swal.close()
        swal({
          title: 'Potencial Global',
          text: 'Upload vimeo realizado!',
          type: 'success',
          showConfirmButton: false,
          timer: 2000
        })
        
        setTimeout( () => {
          this.onNoClick()
        }, 2000 );

        this.fileUpload = ''
        this.fileName = ''

      },(error)=>{
        swal.close()
        swal({
          title: 'Potencial Global',
          text: 'Uplod vimeo não realizado!',
          type: 'error',
          showConfirmButton: false,
          timer: 2000
        })
        
        setTimeout( () => {
          this.onNoClick()
        }, 2000 );
        console.log('error=>', error)
      })

    } else {

      this.cursoService.uploadVimeo(this.fileUpload, this.nome, 'V', this.data.id, this.idProfessor, this.duracao).subscribe(result=>{
        swal.close()
        swal({
          title: 'Potencial Global',
          text: 'Upload vimeo realizado!',
          type: 'success',
          showConfirmButton: false,
          timer: 2000
        })
        
        setTimeout( () => {
          this.onNoClick()
        }, 2000 );

        this.fileUpload = ''
        this.fileName = ''

      },(error)=>{
        swal.close()
        swal({
          title: 'Potencial Global',
          text: 'Uplod vimeo não realizado!',
          type: 'error',
          showConfirmButton: false,
          timer: 2000
        })
        
        setTimeout( () => {
          this.onNoClick()
        }, 2000 );
        console.log('error=>', error)
      })

    }
  }

  
}