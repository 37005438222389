
import { DashboardComponent } from './dashboard/dashboard.component';

import { HomeComponent } from './home/home.component';
import { LoggedInGuard } from './security/loggedin.guard';
import { LoginComponent } from './security/login/login.component';
import { NotFoundComponent } from './not-found/not-found.component';

import { Routes } from '@angular/router';
import { EsqueciSenhaComponent } from './security/esqueci-senha/esqueci-senha.component';
import { RedefinirsenhaComponent } from './security/redefinirsenha/redefinirsenha.component';
import { AlterarsenhaComponent } from './security/alterarsenha/alterarsenha.component';
import { AlunoComponent } from './aluno/aluno.component';
import { FormalunoComponent } from './aluno/formaluno/formaluno.component';

import { CropimageComponent } from './cropimage/cropimage.component';

import { AboutComponent } from './about/about.component';

import { ProfessorComponent } from './professor/professor.component';
import { FormprofessorComponent } from './professor/formprofessor/formprofessor.component';
import { UsuarioComponent } from './usuario/usuario.component';
import { FormusuarioComponent } from './usuario/formusuario/formusuario.component';
import { CursoComponent } from './curso/curso.component';
import { FormcursoComponent } from './curso/formcurso/formcurso.component';
import { SubcategoriaComponent } from './curso/subcategoria/subcategoria.component';
import { FormsubcategoriaComponent } from './curso/subcategoria/formsubcategoria/formsubcategoria.component';
import { CategoriaComponent } from './curso/categoria/categoria.component';
import { FormcategoriaComponent } from './curso/categoria/formcategoria/formcategoria.component';
import { ConcursoComponent } from './curso/concurso/concurso.component';
import { FormconcursoComponent } from './curso/concurso/formconcurso/formconcurso.component';
import { ModuloComponent } from './curso/modulo/modulo.component';
import { FormmoduloComponent } from './curso/modulo/formmodulo/formmodulo.component';
import { CapituloComponent } from './curso/capitulo/capitulo.component';
import { FormcapituloComponent } from './curso/capitulo/formcapitulo/formcapitulo.component';
import { UploadComponent } from './curso/upload/upload.component';
import { BannerComponent } from './banner/banner.component';
import { TabsComponent } from './cursos/tabs/tabs.component';
import { GeradorAcessoComponent } from './aluno/gerador-acesso/gerador-acesso.component';
import { EmpresasComponent } from './empresas/empresas.component';
import { FormempresaComponent } from './empresas/formempresa/formempresa.component';
import { ListaAcessosComponent } from './aluno/lista-acessos/lista-acessos.component';
import { QuestionsComponent } from './curso/questions/questions.component';
import { FormquestionsComponent } from './curso/questions/formquestions/formquestions.component';


export const ROUTES: Routes = [
  {path: '', component: LoginComponent},
  {path: 'esquecisenha', component: EsqueciSenhaComponent},
  {path: 'login', component: LoginComponent},
  {path: 'login/:to', component: LoginComponent},
  {path: 'redefinirsenha/:link', component: RedefinirsenhaComponent},
  {path: 'dashboard', component: DashboardComponent,
    children: [
      {path: '', component: HomeComponent, canActivate: [LoggedInGuard]},
      {path: 'alterarsenha', component: AlterarsenhaComponent, canActivate: [LoggedInGuard]},

      {path: 'about', component: AboutComponent, canActivate: [LoggedInGuard]},
      {path: 'alunos', component: AlunoComponent, canActivate: [LoggedInGuard]},
      {path: 'alunos/form', component: FormalunoComponent, canActivate: [LoggedInGuard]},
      {path: 'alunos/form/:id', component: FormalunoComponent, canActivate: [LoggedInGuard]},
      {path: 'alunos/gerar-acesso', component: GeradorAcessoComponent, canActivate: [LoggedInGuard]},
      {path: 'alunos/lista-acesso/:turma', component: ListaAcessosComponent, canActivate: [LoggedInGuard]},
      {path: 'banner', component: BannerComponent, canActivate: [LoggedInGuard]},

      {path: 'usuarios', component: UsuarioComponent, canActivate: [LoggedInGuard]},
      {path: 'usuarios/form', component: FormusuarioComponent, canActivate: [LoggedInGuard]},
      {path: 'usuarios/form/:id', component: FormusuarioComponent, canActivate: [LoggedInGuard]},

      {path: 'professores', component: ProfessorComponent, canActivate: [LoggedInGuard]},
      {path: 'professores/form', component: FormprofessorComponent, canActivate: [LoggedInGuard]},
      {path: 'professores/form/:id', component: FormprofessorComponent, canActivate: [LoggedInGuard]},
      
      {path: 'products-tabs', component: TabsComponent, canActivate: [LoggedInGuard] },
      {path: 'cursos', component: CursoComponent, canActivate: [LoggedInGuard]},
      {path: 'cursos/form', component: FormcursoComponent, canActivate: [LoggedInGuard]},
      {path: 'cursos/form/:id', component: FormcursoComponent, canActivate: [LoggedInGuard]},
      {path: 'cursos/questions', component: QuestionsComponent, canActivate: [LoggedInGuard]},
      {path: 'cursos/questions/form', component: FormquestionsComponent, canActivate: [LoggedInGuard]},
      {path: 'cursos/questions/form/:id', component: FormquestionsComponent, canActivate: [LoggedInGuard]},

      {path: 'cursos/capitulos', component: CapituloComponent, canActivate: [LoggedInGuard]},
      {path: 'cursos/capitulos/form', component: FormcapituloComponent, canActivate: [LoggedInGuard]},
      {path: 'cursos/capitulos/form/:id', component: FormcapituloComponent, canActivate: [LoggedInGuard]},
      {path: 'cursos/capitulos/upload/:type/:capituloId', component: UploadComponent, canActivate: [LoggedInGuard]},

      {path: 'empresas', component: EmpresasComponent, canActivate: [LoggedInGuard]},
      {path: 'empresas/form', component: FormempresaComponent, canActivate: [LoggedInGuard]},
      {path: 'empresas/form/:id', component: FormempresaComponent, canActivate: [LoggedInGuard]},

      {path: 'cursos/categorias', component: CategoriaComponent, canActivate: [LoggedInGuard]},
      {path: 'cursos/categorias/form', component: FormcategoriaComponent, canActivate: [LoggedInGuard]},
      {path: 'cursos/categorias/form/:id', component: FormcategoriaComponent, canActivate: [LoggedInGuard]},

      {path: 'cursos/modulos', component: ModuloComponent, canActivate: [LoggedInGuard]},
      {path: 'cursos/modulos/form', component: FormmoduloComponent, canActivate: [LoggedInGuard]},
      {path: 'cursos/modulos/form/:id', component: FormmoduloComponent, canActivate: [LoggedInGuard]},

      {path: 'cursos/concursos', component: ConcursoComponent, canActivate: [LoggedInGuard]},
      {path: 'cursos/concursos/form', component: FormconcursoComponent, canActivate: [LoggedInGuard]},
      {path: 'cursos/concursos/form/:id', component: FormconcursoComponent, canActivate: [LoggedInGuard]},

      {path: 'cursos/subcategorias', component: SubcategoriaComponent, canActivate: [LoggedInGuard]},
      {path: 'cursos/subcategorias/form', component: FormsubcategoriaComponent, canActivate: [LoggedInGuard]},
      {path: 'cursos/subcategorias/form/:id', component: FormsubcategoriaComponent, canActivate: [LoggedInGuard]},
      
      {path: 'uploadimage', component: CropimageComponent, canActivate: [LoggedInGuard]},             
      {path: 'home', component: HomeComponent},
      {path: '**', component: NotFoundComponent}
    ]
  }
]