import { Component, OnInit, ViewChild, Inject, ElementRef } from '@angular/core';
import { Validators, FormGroup, FormBuilder, FormGroupDirective } from '@angular/forms';
import { Professor } from '../professor.model';
import { ProfessorService } from '../professor.service';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import swal from 'sweetalert2';
import { BACKEND_FILE } from 'src/app/app.api';

export interface professorFotoData {
  id: number;
}

@Component({
  selector: 'app-formprofessor',
  templateUrl: './formprofessor.component.html',
  styleUrls: ['./formprofessor.component.scss']
})
export class FormprofessorComponent implements OnInit {

  @ViewChild('nome') inputNome:ElementRef;
  @ViewChild(FormGroupDirective) cadastroForm : FormGroup

  professor : Professor

  id : number = undefined
  foto: any
  
  @ViewChild("ckeBiografia") ckeditor: any;
  biografia: string

  constructor(private professorService: ProfessorService,
              private fb: FormBuilder,
              private router: Router,
              public dialog: MatDialog,
              private activatedRoute: ActivatedRoute) {

                this.id = this.activatedRoute.snapshot.params['id']==undefined ? undefined : this.activatedRoute.snapshot.params['id']

              }

  ngOnInit() {

    this.cadastroForm = this.fb.group({
      id: this.fb.control(''),
      nome: this.fb.control('', [Validators.required]),
      biografia: this.fb.control(''),
      email: this.fb.control('', [Validators.required, Validators.email]),
      status: this.fb.control('')
    })

    if(this.id){
      this.getProfessor(this.id)
      this.retornarFoto()
    }else{      
      this.cadastroForm.patchValue({
        status : true
      })
    }
  }

  getProfessor(id: number){

    if(id) {
    
      this.professorService.obterProfessorId(id).subscribe(result => {

        this.cadastroForm.patchValue({
          nome : result.nome,
          email : result.email,
          status : result.status == "A"? true : false
        })
        this.biografia = result.biografia
      })
    }
  }

  gravar(professor : Professor, formDirective: FormGroupDirective){

    professor.biografia = this.biografia
    if(this.id==undefined){
        this.save(professor, formDirective)
    }else{
        this.update(professor)
    }
    
  }
  
  limpar(formDirective: FormGroupDirective){
    
    this.id=undefined
    this.foto = undefined

    formDirective.resetForm()
    this.cadastroForm.reset()
    
    this.cadastroForm.patchValue({
      visibilidade: 'E',
      completo: 'S',
      status : true
    })

    this.inputNome.nativeElement.focus()

  }

  openDialog(): void {
    const dialogRef = this.dialog.open(UploadProfessorFoto, {
      width: '400px',
      height: '600px',
      data:{ id : this.id}
    });

    dialogRef.afterClosed().subscribe(result => {
      this.foto = undefined
      setTimeout( () => {
        this.retornarFoto()
      }, 3000 );
    });
  }

  retornarFoto(){
    this.professorService.obterProfessorFoto().subscribe(result=>{

    result.forEach(element => {
      let valida: string = element
      console.log(element)
      if(this.id.toString() == valida.substring(0,this.id.toString().length)){
        this.foto = BACKEND_FILE + "/foto/professores/" + element
      }
    });

    })
  }
  
  save(professor: Professor, formDirective: FormGroupDirective){

      this.professorService.create(professor).subscribe(result=>{
        
        this.id = result.id

        swal({
          title: 'Potencial Global',
          text: 'Professor cadastrado com sucesso!',
          type: 'success',
          showConfirmButton: false,
          timer: 1500
        })
  
        // formDirective.resetForm();
        // this.cadastroForm.reset()
        // this.biografia = ''

      },(error)=>{
        swal({
          title: 'Potencial Global',
          text: 'Ocorreu um erro no cadastro de professor.',
          type: 'warning',
          showConfirmButton: false,
          timer: 2000
        })
      })

  }

  update(professor: Professor) {

    professor.status = professor.status.toString() == 'true' ? 'A' : 'D'
    professor.id = this.id
    this.professorService.update(professor).subscribe(result=>{
      swal({
        title: 'Potencial Global',
        text: 'Professor alterado com sucesso!',
        type: 'success',
        showConfirmButton: false,
        timer: 2000
      })

      setTimeout( () => {
          this.router.navigate(['/dashboard/professores'])
        }, 1000 );
      },(error: HttpErrorResponse)=>{
    })

  }
}

@Component({
  selector: 'uploadprofessorfoto',
  templateUrl: './uploadprofessorfoto.html',
})

export class UploadProfessorFoto {
  imageChangedEvent: any = '';
  croppedImage: any = ''

  constructor(private professorService: ProfessorService,
              public dialogRef: MatDialogRef<UploadProfessorFoto>,
              @Inject(MAT_DIALOG_DATA) public data: professorFotoData) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }
  imageLoaded() {
      // show cropper
  }
  cropperReady() {
      // cropper ready
  }
  loadImageFailed() {
      // show message
  }

  upload(){

    this.professorService.uploadProfessorFoto(this.croppedImage, this.data.id).subscribe(result=>{

    },(error)=>{
      console.log('error=>', error)
    })
    this.onNoClick()
  }

}