import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { BACKEND_API } from "../app.api";

@Injectable()

export class BannerService{

    constructor(private http: HttpClient){}
    
    deletarBanner(arquivo:string): Observable<any[]> {
      return this.http.get<any[]>(`${BACKEND_API}/files/deletebanner/${arquivo}`)
    }

    obterBannerFoto(): Observable<any[]> {
        return this.http.get<any[]>(`${BACKEND_API}/files/fotobanner`)
    }

    reordenarBanner(ordenacao:any[]){      
      return this.http.post<any>(`${BACKEND_API}/files/reorderBanner`, ordenacao)
      .pipe(map((result: any) => result))
    }

    uploadBanner(file: any, id:number ): Observable<any>{

        let form = new FormData()

            form.append('file', file)
            form.append('id', id.toString())

        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'false',
            "processData": 'false'
          })
        }
        return this.http.post<any>(`${BACKEND_API}/files/uploadbanner`, form)
        .pipe(map((result: any) => result))
    }

}