import { Injectable} from '@angular/core'

@Injectable()

export class NumeroService{

    constructor(){}

  percentual(compra: number, venda: number): string{

    let resultado = ((((Number(venda) - Number(compra))/Number(venda))*100).toFixed(2)).toString().replace(".",",") + "%"

    if(resultado == "-Infinity%" || resultado == "NaN%"){
      resultado = "0%"
    }
    return resultado
  }
}