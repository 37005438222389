import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http'
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { BACKEND_API } from "../app.api";
import { Aluno, AlunoGrid, AlunoAutoComplete } from "./aluno.model";

@Injectable()

export class AlunoService{

    constructor(private http: HttpClient){}

    create(aluno: Aluno): Observable<any>{
        return this.http.post<any>(`${BACKEND_API}/alunos`, aluno)
        .pipe(map((result: any) => result))
    }

    createAcessos(acessos: any): Observable<any>{
        return this.http.post<any>(`${BACKEND_API}/alunos`, acessos)
        .pipe(map((result: any) => result))
    }

    delete(id: number): Observable<any>{
        return this.http.delete<any>(`${BACKEND_API}/alunos/${id}`)
        .pipe(map((result: any) => result))
    }

    obterAlunoAutoComplete(ano:number): Observable<AlunoAutoComplete[]> {
        return this.http.get<AlunoAutoComplete[]>(`${BACKEND_API}/alunos/obteralunoautocomplete/${ano}`)
    }

    obterAcessos(acesso: string): Observable<any[]> {
        return this.http.get<any[]>(`${BACKEND_API}/alunos/turma/${acesso}`)
    }

    obterAlunoComboTurma(idTurma:number, ano:number): Observable<any> {
        return this.http.get<any>(`${BACKEND_API}/alunos/alunocomboturma/${idTurma}/${ano}`)
    }   

    obterAlunoCPF(cpf:string): Observable<any> {
        return this.http.get<any>(`${BACKEND_API}/alunos/cpf/${cpf}`)
    } 

    obterAlunoEmail(email:string): Observable<any> {
        return this.http.get<any>(`${BACKEND_API}/alunos/email/${email}`)
    } 

    obterAlunoId(idAluno:number): Observable<any> {
        return this.http.get<any>(`${BACKEND_API}/alunos/id/${idAluno}`)
    }   

    obterAlunoIdAno(idAluno:number, ano:number): Observable<any> {
        return this.http.get<any>(`${BACKEND_API}/alunos/idano/${idAluno}/${ano}`)
    }   

    obterAlunoMatricula(matriculaAluno:number): Observable<any> {
        return this.http.get<any>(`${BACKEND_API}/alunos/matricula/${matriculaAluno}`)
    }   

    obterAlunoMatriculaAno(matriculaAluno:string, ano:number): Observable<any> {
        return this.http.get<any>(`${BACKEND_API}/alunos/matriculaano/${matriculaAluno}/${ano}`)
    }   

    obterAlunoLista(): Observable<any[]> {
        return this.http.get<any[]>(`${BACKEND_API}/alunos/obterAlunoLista`)
    }    

    obterAlunoListaGrid(): Observable<AlunoGrid[]> {
        return this.http.get<AlunoGrid[]>(`${BACKEND_API}/alunos/grid`)
    }  
    
    update(aluno: Aluno): Observable<any>{
        return this.http.put<any>(`${BACKEND_API}/alunos`, aluno)
        .pipe(map((result: any) => result))
    }
}