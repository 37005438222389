import { Component, OnInit } from '@angular/core';
import swal from 'sweetalert2';
import { Subcategoria, Categoria } from '../../curso.model';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { CursoService } from '../../curso.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-formsubcategoria',
  templateUrl: './formsubcategoria.component.html',
  styleUrls: ['./formsubcategoria.component.scss']
})
export class FormsubcategoriaComponent implements OnInit {

  cadastroForm: FormGroup
  subcategoria: Subcategoria
  categoria: Categoria[]

  id: number = undefined
  
  constructor(private cursoService: CursoService,
              private fb: FormBuilder,
              private router: Router,
              private activatedRoute: ActivatedRoute) { 

      this.id = this.activatedRoute.snapshot.params['id']==undefined ? undefined : this.activatedRoute.snapshot.params['id']

    }

  ngOnInit() {
    this.cadastroForm = this.fb.group({
      id: this.fb.control(''),
      nome: this.fb.control('', [Validators.required]),
      descricao: this.fb.control(''),
      idCategoria: this.fb.control('', [Validators.required]),
      status: this.fb.control('')
    })

    this.cursoService.obterCategoriasCombo().subscribe(retorno => {
      this.categoria = retorno
    })

    if(this.id!=undefined){
      this.load()
    }
  }

  load(){
    this.cursoService.obterSubategoriaId(this.id).subscribe(result => {
      this.cadastroForm.patchValue({
        id: result.id,
        nome: result.nome,
        descricao: result.descricao,
        idCategoria: result.idCategoria,
        status: result.status == "A"? true : false
      })
    })
  }

  gravar(subcategoria : Subcategoria){
    if(this.id == undefined){
      this.save(subcategoria)
    }else{
      this.update(subcategoria)
    }
  }

  save(subcategoria : Subcategoria){

    this.cursoService.createSubcategoria(subcategoria).subscribe(result=>{
      swal({
        title: 'Potencial Global',
        text: 'Subcategoria cadastrada com sucesso!',
        type: 'success',
        showConfirmButton: false,
        timer: 1500
      })

      this.cadastroForm.reset()

      },(error)=>{
      swal({
        title: 'Potencial Global',
        text: 'Ocorreu um erro no cadastro de subcategorias.',
        type: 'warning',
        showConfirmButton: false,
        timer: 2000
      })
    })
  }

  update(subcategoria: Subcategoria) {

    subcategoria.status = subcategoria.status.toString() == 'true' ? 'A' : 'D'
    subcategoria.id = this.id

    this.cursoService.updateSubcategoria(subcategoria).subscribe(result=>{

    swal({
      title: 'Potencial Global',
      text: 'Subcategoria alterada com sucesso!',
      type: 'success',
      showConfirmButton: false,
      timer: 2000
    })

    setTimeout( () => {
        this.router.navigate(['/dashboard/cursos/subcategorias'])
    }, 2000 );
    },(error: HttpErrorResponse)=>{
    })
    }
  }